import { ExpandMore } from '@mui/icons-material';
import { Accordion, AccordionDetails, AccordionSummary, Button, Typography } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import {Helmet} from "react-helmet";

export default function AboutPage() {
  const spotTypes = ["Silver","Gold","Platinum"];

  const helpInfo = [
	{
		tit:"Buying Process", desc:'At Rentease our priority is that the transaction process will be secure and convenient.\n\n Rentease will store no bank account or credit card information on our site, all that information will be stored with our partner Stripe.\n\n As you set up your user account with Rentease you will setup your transaction account with Stripe.  For more detailed information on the process, security, and convenience of the Stripe transaction process please follow this link.',
	},
	{
		tit:"Meet the Gold Guys",desc:`Renters may cancel their rental through our site or mobile app, and the cancelation is effective immediately.  Whether the renter receives a full refund , partial refund, or receives no refund depends on the owners discretion.  The total amount refunded will depend on when the renter cancels the rental request, length of rental and type of rental.\n\nRenters may cancel free of charge up to 24 hours before the rental time begins.  Renters who book within 24 hours of rental pickup have one hour after booking to cancel for free.  If a renter wants to cancel a rental we recommend they notify the owner as soon as possible via Rentease messaging and to process the cancellation themselves through the Rentease website or app.`
	},
	{
		tit:"Our Strategy",desc:"Rentease is a global community built on trust and inclusion.  We’re dedicated to providing a safe and secure marketplace for renters, and owners, and we are here to support you 24/7 whenever you have questions or need a hand.\n\nTrust\nTrust is essential to a marketplace that works.  With secure payments, fraud protection and authentic reviews.  We make sure all members of our community can provide equipment and rent with confidence.\n\nSecurity\nKeeping your account safe and secure is a top priority.  From payment protection to fraud prevention, we have tools in place to reduce risks in financial transactions and maintain trust between parties that may not know each other.\n\nSupport\nWe have teams on call 24 hours a day, seven days a week to support owners, and renters who require assistance or have questions.  If there is anything you need, please get in touch.\n\n\nSafety\nRentease offers a range of tools and resources that protect the privacy and safety of renters and owners.  We support owners by educating them about best practices for providing clean and well maintained equipment, and we use technology to verify the identities of owners and renters whenever possible.  We advise our community to follow state and local laws that may apply"
	},
	{
		tit:"Our Promise",desc:"You are allowed to list anything you would like, with the exception of firearms or anything that is considered illegal in your state.  You may list things and you are in charge of how long they may have it for and how much they rent it for.  Rentease gets a certain percentage of the money but most will be given to you as the lister.\n\nRentease does all they can to make sure everything runs smoothly and efficiently on both sides: the lister and the customer.  Please let us know if there is anything we can do to make your experience greater."
	},
    ]
	React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])
  return (
    <div className='defPageMarg'>
          <Helmet>
            <meta charSet="utf-8" />
            <title>About Page</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="About Page"/>
            <meta name="keywords" content="Silver, Gold, Tradding, Bars, Coins"/>
        </Helmet>
        <div style={{padding:30}}>
            <div className='defaultTitle'>
                ABOUT GOLD GUYS BULLION
            </div>
	<div className="helpAccRoot">
      <Accordion
	  className="helpAccRootRoot">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
		  className="helpAccSum"
        >
          <Typography className="helpAccHead" inline>Buying Process</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="helpAcclb" >
          <div className='mobileTextCenter'>
          Thank you for choosing Gold Guys Bullion as your source to acquire real wealth!  <br/>
          We are excited for you to be here with us and to ultimately receive the best quality of product and experience the precious metals industry has to offer! <br/><br/>
          We offer five different payment options when you have finished browsing our website and are ready to checkout with the items in your cart. <br/><br/>
          Instructions for each of the five payment options are as follows:  <br/><br/>
<b style={{textDecoration:'underline'}}>eCheck</b> – Please send your eChecks to our established deluxe eCheck account via email at sales@goldguys.com , please fill the memo line with your invoice #.<br/><br/>
We use deluxe.com as our third-party authorizer of ACH processing. In order to fulfill your order using eCheck you must first establish an account through the deluxe website. Once an account is established, eChecks can be purchased for as little as $0.48 per check. Almost all banking institutions can seamlessly be connected to your deluxe eChecks account. Please refer to this <Link style={{textDecoration:'underline'}} target='_blank' to="https://orderpoint.deluxe.com/personal-checks/faq.htm">page</Link> if you have any questions or curiosities of concern in your experience of processing payments via eCheck. 
<br/>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*This avenue of payment (eCheck) is quickly becoming the precious metals industry's preferred payment type; this is due to the high-level of accessibility, timeliness, veracity, and security.  
            <br/><br/>
            <b style={{textDecoration:'underline'}}>Zelle</b> – Please send Zelle transfers to our established Zelle account @ sales@goldguys.com , please fill the memo line with your invoice #. <br/><br/>Like a money order, Zelle has instant money transfer capabilities that are tremendously beneficial for both parties to pay and receive payment amounts directly from and to our respective bank accounts even if they are between different banking institutions. 
            <br/><br/>
            <b style={{textDecoration:'underline'}}> Wire</b> - Once your invoice is received you will find the wire instructions attached. Please identify purpose of payment in subject line/memo field by using your invoice #. <br/> <br/>
            <b style={{textDecoration:'underline'}}>Paypal</b> – Paypal payments are processed during the checkout process on our website. We accept payment for the following cards: VISA, Mastercard, American Express, and Discover. This payment method is subject to processing fees.
            <br/><br/>
            <b style={{textDecoration:'underline'}}>Credit Card/Debit Card</b> – Payments are processed during the checkout process on our website.  We accept payment for the following cards: VISA, Mastercard, American Express, and Discover. We also accept Google Pay, Apple Pay, and Link. We use Stripe integrations to allow for seamless payment completion on our website during the cart checkout process. This payment method is subject to processing fees.
            <br/><br/>
            A shipment tracking number will be provided to you via email once the receipt of payment is confirmed by us and your metals are being securely packaged.<br/><br/>
            For further inquiries on the Buying Process please refer to our  <Link to="/terms">Terms of Service</Link>
            </div>
          </Typography>

        </AccordionDetails>
      </Accordion>

      <Accordion
	  className="helpAccRootRoot">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
		  className="helpAccSum"
        >
          <Typography className="helpAccHead" inline>Meet the Gold Guys</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="helpAcclb" >
          <div className='mobilefb'>
            <div className='aboutDetials'> 
              <div className='aboutImages'><img src="/static/images/joe-beasy.webp" width={110} height={110} className='aboutImage' alt="Joe Beasy"/></div>
              <div style={{textAlign:'center',fontWeight:'bold'}}>JOE BEASY</div>
            </div>
            <div className='aboutDetials'>
            Joe Beasy is a seasoned entrepreneur. Blending a father's intuition with a CEO's tenacity, Joe enjoys the role of training and encouraging his staff. Raised in a working-class family on the Iron Range in Northern Minnesota, Joe was instilled with an intense sense of loyalty to family, friends, and community. Having grown up among the lakes and forests along the Canadian border, when he's not working, you'll most likely find him with in the great outdoors enjoying canoeing, fishing, and taking in the awesome beauty of God's creation.
            </div>
          </div>
          <div className='mobilefb'>
            <div className='aboutDetials'>
              <div><img src="/static/images/shane-maguire.webp" width={110} height={110} className='aboutImage' alt="Shane Maguire"/></div>
              <div style={{textAlign:'center',fontWeight:'bold'}}>SHANE MAGUIRE</div>
            </div>
            <div className='aboutDetials'>
            Shane Maguire is a proud American, New Zealand native. At 21 years old Shane landed in the states by way of a purpose built, square rigged sailing ship. The ship was built in New Zealand for the motion picture "The Bounty" starring Mel Gibson and of course, Anthony Hopkins and a number of other A listers.  Shane moved to the states permanently at the age of 24 in his pursuit of the American dream. An entrepreneurial idealist with a zeal for new ideas, he challenges and inspires people to also do their best.  Shane co-founded The Gold Guys, October 2008, with long time friend and business partner, Joe Beasy. Together, they arguably estabished one of the most successful and long lasting brands in the precious metals industry. Over the years, Shane has pursued and enjoyed success in many business ventures and continues to pursue opportunities to this day. 


Shane's fondness for aviation and sailing is among his many interests. As an adventure seeker, he has balanced success, life and his pursuit of happiness, which begins with his 3 kids and beautiful wife. 
</div>
          </div>
          <div className='mobilefb'>
            <div className='aboutDetials'> 
              <div className='aboutImages'><img src="/static/images/dbimage.png" width={110} height={110} className='aboutImage' alt="Joe Beasy"/></div>
              <div style={{textAlign:'center',fontWeight:'bold'}}>Darren Beenken</div>
            </div>
            <div className='aboutDetials'>
            Darren Beenken is a young entrepreneur that is viewed by his peers as a bold visionary. Using his professional experience in public accounting, private contracting, while carrying desirable traits echoes to people around him as worthy of leadership; Darren paved a new path for the public to acquire true wealth with the creation of Gold Guys Bullion, LLC. Being the youngest of five brothers, he is described as wise beyond his years due to finding that learning from others is the quickest way to further your own knowledge base.  Coincidentally, Darren loves the precious metal's version of a little brother: Silver. As an avid storyteller he enjoys movies, reading, lore, memes, and other artistic media shared across the world. If you find him outside, he is likely attempting to cut strokes off his golf game. 
            </div>
          </div>
        </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
	  className="helpAccRootRoot">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
		  className="helpAccSum"
        >
          <Typography className="helpAccHead" inline>Our Promise</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="helpAcclb" >
          <div className='mobileTextCenter'>
          Our successes are built from simple promises; We want you to own more for less, and we will pay you the most for your metals when you are ready to sell. By developing a relationship with you, our valued customer, on a foundation of trust, knowledge, and experience we will build bridges of success; together, for years to come. This is what we love to do, getting real wealth into the hands of We The People, and we want you to love your experience when becoming a customer with us. Security, Service, and Sales! We are the best in the business because we pass along the largest discount possible to you when acquiring physical bullion from our Mint and wholesale partners! 
          </div>
        </Typography>
        </AccordionDetails>
      </Accordion>
      <Accordion
	  className="helpAccRootRoot">
        <AccordionSummary
          expandIcon={<ExpandMore />}
          aria-controls="panel1a-content"
          id="panel1a-header"
		  className="helpAccSum"
        >
          <Typography className="helpAccHead" inline>Our Story</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Typography className="helpAcclb" >
          <div className='mobileTextCenter'>
            <div style={{fontWeight:'bold',textAlign:'center'}}>
            The American Dream
            </div>
            <div><br/>
            Joe and Shane met in 1991 while working on a state-of-the-art electric vehicle project. While Joe was heavily engaged in the project in the Midwest, Shane kept equally busy on the West Coast. The distance between them was no match for the new business partnership which quickly developed into what is now a multi-decade long friendship.
            </div>
            <div><br/>
            In 2008, Joe and Shane opened their flagship store in Minnesota at the Mall of America.  As business partners, they discovered that the gold market could be the perfect place to blend their entrepreneurial spirits with their desire to bring opportunity to others. They hand-picked their crew from the people closest to them, creating a unique work environment based on trust and integrity. Now, with several locations, Joe and Shane spend their time overseeing the operation. They credit The Gold Guys’ success to their highly trained, self-sufficient staff. Now Joe and Shane can focus their time traveling between store locations and engaging in community appearances. Joe and Shane want you to know them, the Gold Guys; that’s why they put their faces on everything they do. The Gold Guys is the brand but Joe and Shane want you to know they love silver too! By providing you with direct access to acquire the finest bullion products anywhere in the world, the next chapter of this story is just getting started. 
            </div>
            <div><br/>
            “We would love to have you side-by-side with us ready to embark upon this grand adventure that is sitting on the horizon! Own more for less, it's that simple!” - The Gold Guys
            </div>
          </div>
        </Typography>
        </AccordionDetails>
      </Accordion>
    </div>
        </div>
    </div>
  );
}