import React, { createContext, useContext, useState } from 'react';
import { getFunctions, httpsCallable } from "firebase/functions";
import { SpotContext } from '../../providers/SpotProvider';
import SpotTicket from './SpotTicket.tsx';
import { CircularProgress } from '@mui/material';


export default function CacheSpots(props){
    const {priceS,priceG,priceP, setPriceS,setPriceG,setPriceP } = useContext(SpotContext);

    const functions = getFunctions();
    const [loading, setLoading] = React.useState(true);
    const [loadingSpot, setLoadingSpot] = React.useState(true);

  
    React.useEffect(() => {
        const addMessage = httpsCallable(functions, 'fetchSpotCache');
        addMessage()
        .then((result) => {
            setPriceS(parseFloat(result.data.spotAG))
            setPriceG(parseFloat(result.data.spotAU))
            setPriceP(parseFloat(result.data.spotPT))
            setLoading(false)
            setLoadingSpot(false)

        }).catch((err)=>{
            console.log(err)
        })
    }, []);

    if(loadingSpot){
        return(
          <div style={{background:'transparent',margin:'10px auto',textAlign:'center',display:'flex'}}>
            <CircularProgress/> <div style={{background:'transparent',marginTop:10,marginLeft:30}}>Loading real time spot.</div>
          </div>
        )
      }else{

    return (
        <div className='fb fgrey sa w100'>
            {loading ? <></> :<>
        <div className='fb fgrey'>
            <div className='ma fgrey'>Silver</div>
            <div className="ma fgrey" style={{marginLeft:10,fontSize:'large',fontWeight:'bold'}}>${parseFloat(priceS).toFixed(2)}</div>
        </div>
        <div className='fb fgrey'>
        <div className='ma fgrey'>Gold</div>
        <div className="ma fgrey" style={{marginLeft:10,fontSize:'large',fontWeight:'bold'}}>${parseFloat(priceG).toFixed(2)}</div>
    </div>
    <div className='fb fgrey'>
    <div className='ma fgrey'>Platinum</div>
    <div className="ma fgrey" style={{marginLeft:10,fontSize:'large',fontWeight:'bold'}}>${parseFloat(priceP).toFixed(2)}</div>
    </div>
    </>
    }
    </div>
    )
    }
}
