import * as React from 'react';
import SearchDisplayComonent from '../MainComponents/SearchDisplayComponent.tsx';

export default function SellPage() {

  const spotTypes = ["Silver","Gold","Platinum"];
  React.useEffect(() => {
    window.scrollTo(0, 0)
  }, [])
  return (
    <div className='defPageMarg' style={{textAlign:'center'}}>
        <h1>HOW CAN I SELL WITH THE GOLD GUYS?</h1><br/>
        <div>The Gold Guys make selling your valuable gold, silver, platinum, and palladium easy. We want you to be involved with your transaction from start to finish. Whether you come into one of our convenient locations, use our mail-in service, host a gold party or use our at-home service, you can be confident that our gold buying process is safe, respectful and trusted. And you’ll know that you’re getting the best price for your gold because we don’t just match our competitor’s prices, we give you more.</div><br/><br/>
        <a href="https://www.goldguys.com/how-to-sell/">More Information</a>
    </div>
  );
}