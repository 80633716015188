import * as React from 'react';

export default function OutOfUS(){
    return(
        <div>
            <div style={{fontSize:'xx-large',marginTop:300,textAlign:'center'}}>
                WE DO NOT LIKE YOUR BUSINESS
            </div>
        </div>
    )
}