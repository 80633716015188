import * as React from 'react';
import LoginHeader from './Login/LoginHeader.tsx';
import {Helmet} from "react-helmet";
import { UserContext } from '../providers/UserProvider.jsx';
import { useNavigate } from 'react-router-dom';

export default function Login(){
    const user = React.useContext(UserContext);
    const nav = useNavigate()

    React.useEffect(()=>{
        if(user){
            nav('/myaccount')
        }
    },[user])

    return (
        <div className='defPageMarg'>
        <Helmet>
            <meta charSet="utf-8" />
            <title>Login</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Login Page"/>
            <meta name="keywords" content="Silver, Gold, Tradding, Bars, Coins"/>
        </Helmet>
            <LoginHeader/>
        </div>
    )
}