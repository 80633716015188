import React from 'react';
import { PayPalScriptProvider, PayPalButtons } from '@paypal/react-paypal-js';
import { useCheckout } from '../../providers/CheckoutProvider';
import { getFunctions, httpsCallable } from "firebase/functions";

const PayPalButton = ({onApproveCallback,setLoading}) => {

  const {getCartFinalTotal} = useCheckout();
  const functions = getFunctions();
  console.log("cart final total : ", getCartFinalTotal())
  let transactionID = ""

  return (
    <PayPalScriptProvider options={{ "client-id": "AfdV9-Ya2lnzYc9SZuayomRXevdayKvL7AGiUqbhELAplGkOPa46PYcWT85a5CfFDUOPaDfYPLcTbwXO" }}>
      <PayPalButtons
        createOrder={(data, actions) => {
          console.log(data)
          const payment = parseFloat(getCartFinalTotal()).toFixed(2)
          return actions.order.create({
            purchase_units: [{
              amount: {
                currency_code:'USD',
                value: payment
              },
            }],
            intent:'CAPTURE'
          });
        }}
        onApprove={(data, actions) => {
          return actions.order.capture().then((details) => {
            //console.log(details.purchase_units[0].payments[0].captures[0].id)
            console.log(details)
            let id = details.purchase_units[0].payments.captures[0].id

            onApproveCallback(id)
          });
        }}
      />
    </PayPalScriptProvider>
  );
};

export default PayPalButton;
