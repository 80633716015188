import * as React from 'react';
import TopHeader2 from '../Nav2.tsx';

export default function ShutDown(){

    return (
        <div>
            <TopHeader2/>
        </div>
    )
}