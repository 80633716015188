import { Button, CircularProgress } from '@mui/material';
import * as React from 'react';
import { Link, useNavigate } from "react-router-dom";
import { getFunctions, httpsCallable } from "firebase/functions";
import { useCheckout } from '../../providers/CheckoutProvider.jsx';
import Checkbox from '@mui/material/Checkbox';
import PayPalButton from './PayPalContainer.tsx';

export function moneyFormat(number){
    if (typeof number === 'string' || number instanceof String){  
        if(number == "TBD"){
            return "TBD"
        }
    }
    return parseFloat(number).toFixed(2)
}

export default function SummaryBlock(props){
    const functions = getFunctions();
    const nav = useNavigate()
    const [disableContinue, setDisableContinue] = React.useState(false)
    const [shippingSelected, setShippingSelected] = React.useState(false);
    const [finalAddressObject, setFinalAddressObject] = React.useState({});
    const [pageSubTotal, setPageSubTotal] = React.useState(0);
    const [taxTotal, setTaxTotal] = React.useState(0);
    const [shipTotal, setShipTotal] = React.useState(0);
    const [fullTotal, setFullTotal] = React.useState(0);
    const [compleLoader,setCompleteLoader] = React.useState(true)
    const [continueLoader,setContinueLoader] = React.useState(false)
    const [summaryUpdate, setSummaryUpdate] = React.useState(false)
    const [checkboxSelected, setCheckboxSelected] = React.useState(false)
    const [loadingTax, setLoadingTax] = React.useState(true)
    const [serviceFee, setServiceFee] = React.useState(0)
    const [isPayPalOrder, setIsPayPalOrder] = React.useState(true)

    const [shipAddObj, setShipAddObj] = React.useState({});
    const [isLoading, setIsLoading] = React.useState(false)
    const { continueDisableButton,setSubTotal,getDisableContinue,getAddress, getShippingPrice ,getCartSubTotal,getCartFinalTotal,cartSubTotal,shippingPriceData,getTax,setPayTypeObj,getPayTypeObj,setShippingPrice, payType,cartFinalTotal} = useCheckout();
    const [payPalLoading, setPayPalLoading] = React.useState(false);


    var pageName = window.location.pathname;
    var showTotalBlock = pageName == "/shipping" || pageName == "/review"
    var isPaymentPage = pageName == "/review"
    var isCompletePage = pageName == "/ordercomplete"
    var nextString = "/shipping"
    if(pageName == "/cart"){
        nextString = "/shipping"
    }
    if(pageName == "/shipping"){
        nextString = "/review"
    }
    if(pageName == "/review"){
        nextString = "/ordercomplete"
    }

    async function continueShipping(){
        if(pageName == "/shipping"){
            setContinueLoader(true)
            if(props.commitAddress){
            const res = await props.commitAddress()
            if(res == false){
                setContinueLoader(false)
                return
            }else{
                nav(nextString)
                setSummaryUpdate(true)
            }
        }
        }else{
            nav(nextString)
        }       
    }
    
    function cardCheckout(){
        const addMessage = httpsCallable(functions, 'stripeCheckout');
        addMessage()
        .then((result) => {
            //nav(result.data.stripecheckout)
            window.location.href = result.data.stripecheckout;
        })
    }


    function completeOrder(){
        if(getPayTypeObj() == "" || getPayTypeObj() == undefined){
            alert("Please select Pay Type.")
            return
        }
        if(!checkboxSelected){
            alert("Please check the checkbox.")
            return
        }
        if(props.payMeth == "Credit Card"){
            setDisableContinue(true)
            setCompleteLoader(true)
            cardCheckout()
        }else if(props.payMeth == "PayPal"){
            alert("PayPAL FLOW")
            setDisableContinue(true)
            setCompleteLoader(true)
            return
        }else{
        setDisableContinue(true)
        setCompleteLoader(true)
        const addMessage = httpsCallable(functions, 'completeOrder');
            addMessage({orderType:props.payMeth})
            .then((result) => {
                alert("Order Placed!")
                /*setCartSubTotal(0)
                setSubTotal(0)
                setShipTotal(0)
                setFullTotal(0)
                setTaxTotal(0)*/
                //localStorage.removeItem('cart')
                //nav('/ordercomplete',{state:{finalAddressObject:finalAddressObject,shipAm:shipTotal}})

                /*const newWindow = window.open(result.data.res.result, '_blank', 'noopener,noreferrer')
                if (newWindow) newWindow.opener = null*/
                nav('/ordercomplete',{state:{finalAddressObject:finalAddressObject,shipAm:shipTotal}})
            }).catch((err)=>{
                console.log(err)
                alert("Order Process Error. Please try again later.")
                nav('/cart')

            })
        }
    }
    const handleChecked = (event) => {
        setCheckboxSelected(event.target.checked);
      };
    // calculate order
    React.useEffect(()=>{
        setPageSubTotal(getCartSubTotal())
        setTaxTotal(getTax())
        if(isPaymentPage){
            setCompleteLoader(false)
        }
        if(getCartSubTotal() >= 199){
            setShipTotal(0)
            setShippingPrice(0)
        }else{
            setShippingPrice(9.95)
            setShipTotal(9.95)
        }
        if(isPaymentPage && (props.payMeth == "Credit Card" || props.payMeth == "PayPal") ){
            //setServiceFee((getCartFinalTotal() * 0.029) + .3)
            let total = parseFloat(getCartSubTotal()) + getShippingPrice() + getTax()
            console.log("CALC SERV")
            console.log(total)
            let servFee = ((total) * .039)
            setServiceFee(servFee)

        }
        setFullTotal(getCartFinalTotal())
        localStorage.setItem('lastcarttotal',getCartFinalTotal())

        setLoadingTax(props.loadingTax)
        const storageData = JSON.parse(localStorage.getItem('cart')) || [];
        if(storageData && storageData.length == 0){
            setPageSubTotal(0)
            setDisableContinue(true)
        }



        //setDisableContinue(true)
    },[cartSubTotal,shippingPriceData,summaryUpdate,props.sumUP,props.calculatedtotal,props.loadingTax,props.payMeth,cartFinalTotal])

    React.useEffect(()=>{
        if(props.payMeth == "PayPal"){
            setIsPayPalOrder(true)
        }else{
            setIsPayPalOrder(false)
        }

    },[props.payMeth])
    
    function onApproveCallback(id){
        console.log("WE MADE IT BAXK ")
        setContinueLoader(true)
        setPayPalLoading(true)
        
        
        const addMessage = httpsCallable(functions, 'createInvoiceFromPayPal');
        return addMessage({paypalID: id})
        .then((result) => {
            //nav(result.data.stripecheckout)
            alert("PayPal Approved")
            nav('/ordercomplete',{state:{finalAddressObject:finalAddressObject,shipAm:shipTotal}})
            return
        })
        
    }


    if(isPaymentPage && loadingTax){
        return(<div style={{textAlign:'center',marginTop:50}}><CircularProgress/></div>)
    }else{

    return(                    
    <div className='summaryBlock'>
        <div className='defaultTitle2'>Summary</div>
        <hr/>
        <div className='fb jsb'>
            <div>
                Subtotal
            </div> 
            <div>
                {pageSubTotal != undefined ? '$'+moneyFormat(pageSubTotal):<></>}
            </div>
        </div>
        {
            showTotalBlock ? 
            <div className='fb jsb'>
            <div>
                Shipping
            </div> 
            <div>
                {shipTotal != 0 ? `$${moneyFormat(shipTotal)}` : `$${moneyFormat(shipTotal)}`}
            </div>
        </div>
        :
        <></>
        }
        {
            showTotalBlock ? 
            <div className='fb jsb'>
            <div>
                Tax
            </div> 
            <div>
            {taxTotal != 0 ? `$${moneyFormat(taxTotal)}` : <>{isPaymentPage ? `$${moneyFormat(taxTotal)}`: "TBD"}</>}
            </div>
        </div>
        :
        <></>
        }
                {
            isPaymentPage && (props.payMeth == "Credit Card" || props.payMeth == "PayPal" )  ? 
            <div className='fb jsb'>
            <div>
                Service Fee
            </div> 
            <div>
                ${moneyFormat(serviceFee)}
            </div>
        </div>
        :
        <></>
        }
        {
            showTotalBlock ? 
            <div className='fb jsb'>
            <div>
                Total
            </div> 
            <div>
                ${moneyFormat(fullTotal)}
            </div>
        </div>
        :
        <></>
        }

        <hr/>
        <div className='fb jc'>
            {isPaymentPage ?
                <>
               {compleLoader ? <div><CircularProgress/></div> :(<>
               <div>
               <div className='fb' style={{marginBottom:20}}>
                    <div>
                <Checkbox 
                
                            checked={checkboxSelected}
                            onChange={handleChecked}/>
                            
                    </div>
                    <div style={{marginTop:8,textAlign:'center'}}>
                    By checking this box you agree to our <a href="/terms" target='_blank' style={{textDecoration:'underline'}}>Terms and Conditions</a> + <a href='/privacy' target='_blank' style={{textDecoration:'underline'}}>Privacy Policy</a>
                    </div>
                </div>
                {isPayPalOrder ? 
                
                <>
                { payPalLoading ? <div style={{textAlign:'center'}}>
                    <CircularProgress/>
                    <div>Completing Order ...</div>
                        </div>:
                    <>{checkboxSelected && (
                    <div>
                        <PayPalButton onApproveCallback={onApproveCallback} setLoading={setPayPalLoading}/>
                    </div>)}
                    </>
                }
                </>
                :
                <div style={{textAlign:'center'}}>
               <Button variant='contained' onClick={()=>{completeOrder()}}>Complete Checkout</Button>
               </div>
                }
               </div>
               </>
               )}</>
               
                : 
                <>
                {continueLoader ? <div><CircularProgress/></div> :(<><Button variant='contained' onClick={()=>{continueShipping()}} disabled={getDisableContinue()}>Continue Checkout</Button></>)}
                </>
}
        </div>
        <hr/>
        {!isPaymentPage && (
        <div className='fb jc'>
            <Link to="/">
                <Button variant='contained'>Back to Shopping</Button>
            </Link>
        </div>
        )}
    </div>)
    }
}