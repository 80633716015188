import React from 'react'
import { useNavigate } from 'react-router-dom';

export default function OptoutURL(){
    const nav = useNavigate()
    function setCookie(name, value, days) {
        const date = new Date();
        date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Convert days to milliseconds
        const expires = "expires=" + date.toUTCString();
        document.cookie = name + "=" + value + ";" + expires + ";path=/";
    }

    React.useEffect(()=>{
        setCookie("privacypolicy", false, 30);
        nav("/")
    },[])
    return(<div>
        out out url 
    </div>)

}