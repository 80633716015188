import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ListIcon from '@mui/icons-material/List';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, ClickAwayListener, Divider, Drawer, Grow, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, TextField } from '@mui/material';
import { Link } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { UserContext } from '../providers/UserProvider.jsx';
import MenuDropDownSilver from './SmallComponents/MenuDropDown.tsx';
import MenuDropDownGold from './SmallComponents/MenuDropDownGold.tsx';
import MenuDropDownPlat from './SmallComponents/MenuDropDownPlat.tsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SearchIcon from '@mui/icons-material/Search';
import CacheSpots from './SmallComponents/CacheSpots.tsx';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SpotTicket from './SmallComponents/SpotTicket.tsx';
import {Helmet} from "react-helmet";

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


export default function TopHeader(props) {
  const user = React.useContext(UserContext);
  const [value, setValue] = React.useState(0);
  const [silverOpen, setSilverOpen] = React.useState(false);
  const [goldOpen, setGoldOpen] = React.useState(false);
  const [platOpen, setPlatOpen] = React.useState(false);
  const [showMarketing, setShowMarketing] = React.useState(true);
  const [searchVal, setSearchVal] = React.useState('');
  const [silvDOpen, setSilvDOpen] = React.useState(false);
  const [goldDOpen, setGoldDOpen] = React.useState(false);
  const [platDOpen, setPlatDOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [mobileAccount, setMobileAccount] = React.useState('/login');


  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);

  // Create a function to update the screenWidth state
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  if(props.selectedHeader != undefined && props.selectedHeader != null){
    setValue(props.selectedHeader)
  }
  React.useEffect(() => {
    // Add an event listener for the "resize" event
    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []); // Empty dependency array to run the effect only once
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const closeDrop = (event) =>{
    setLoginOpen(false)
  }


  const spotTypes = ["Silver","Gold","Platinum"];

    const [drawer, setDrawerState] = React.useState(false);

    const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });



    
   
    const settings = {
      dots: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
    };


  return (
    <div className='defaultBackgoundOVer' style={{backgroundRepeat:'repeat',width:'100%'}}>
          <div>
    </div>
    <div className='headerHold'>
    <div className="fb fgrey">
    {screenWidth > 1058 ?
    <div className='fb fgrey'>
    <Link to="/" onClick={()=>{setValue(0)}} className='fgrey'>
            <Button style={{padding:0}}>        
              <img src="/static/images/4.png" alt="Gold Guys Bullion" width={100} height={100} style={{marginTop:-10,marginBottom:-10,background:'transparent'}} className='fgrey'/>
        </Button>
          </Link>
    <div className='fgrey fontS' style={{textWrap:'nowrap',margin:'auto'}}><b>Free Shipping</b><br/>on Orders $199+</div>

    </div>
    :<></>}
    <div className='fb space_around fgrey spotCont'>
      <CacheSpots/>
    </div>

    </div>
    <div>
      <SpotTicket/>
    </div>
    {screenWidth > 1058 ? 
    <div style={{background:'#ffc02c',height:50}}>
    </div> 
    :
    <div style={{background:'#ffc02c'}}>
    </div>
    }
    </div>
    { screenWidth > 1058 ? <div className='fb jsb horMarg20 headHeight'></div>:
    <div className='fb jsb horMarg20' style={{height:100}}></div>
    }
    {screenWidth < 1058 ?
    <>
      <div className="mobileIconLeft">
        <Link to="/" className='imageLinkBack'>
          <img src="/static/images/4.png" alt="Gold Guys Bullion" width="80" height="80" />
          </Link>
                  </div>
      </>
      :<></>
    }{ showMarketing &&
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Gold Guys Bullion</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Gold Guys Bullio"/>
            <meta name="keywords" content="Silver, Gold, Tradding, Bars, Coins"/>
        </Helmet>
        <div className='background-overlay homeHeaderBackgourd w100' 
              style={{backgroundImage: `url(/static/images/GoldGuysVar2.png)`}}
        >
          <div className='homePageCustImagCont'>
          <img className='homePageCustImag' src="/static/images/welcome2.png" width={150} height={150} />
          </div>
        </div>
        <div style={{width:'100%',color:'black',background:'red',textAlign:'center',fontSize:'large'}}>
        Website down for maintenance, we are currently not offering our services. Thank you for your understanding.
        </div>
        <div className='greyHor'>
        </div>
        <div>
              <div style={{margin:30}}>
              {screenWidth > 1058 ?
              <Slider {...settings}>
                  <div>
                    <img src='/static/images/GGBTheGuys(2).png' alt="Gold Guys Bullion" style={{width:'100%'}} height={500}/>
                  </div>
                  <div>
                    <a href="https://www.ebay.com/str/rejuvenatedjewelry" target='_blank'>
                    <img src='/static/images/GGBDesktop.png' alt="Gold Guys Bullion" style={{width:'100%'}} height={500}/>
                    </a>
                  </div>
                  <div>
                    <img src='/static/images/hpimage1.webp' alt="Gold Guys Bullion" style={{width:'100%'}} height={500}/>
                  </div>

                </Slider>
                :
                <Slider {...settings}>
                  <div>
                    <img src='/static/images/this-is-fine-this-is-fine-meme.gif' alt="Gold Guys Bullion" style={{width:'100%'}} height={400}/>
                  </div>
                  <div>
                    <a href="https://www.ebay.com/str/rejuvenatedjewelry" target='_blank'>
                    <img src='/static/images/GGBMobile.png' alt="Gold Guys Bullion" style={{width:'100%'}} height={400}/>
                    </a>
                  </div>
                  <div>
                    <img src='/static/images/hpimage1.webp' alt="Gold Guys Bullion" style={{width:'100%'}} height={400}/>
                  </div>

                </Slider>
                }
              </div>
              <div>
              </div>
        </div>
        <hr/>
        <div className='fb fw'
                      
                      >
          <div className='hpboxBox'>
                <div className='hpboxHeader'>Accredited Business</div>
                <div className='hpboxbody'>For over 15 years The Gold Guys have been an industry leader in operational excellence and a pioneer of innovation within the precious metals market. With over 300,0000 transactions with tens of thousands of happy customers we have built a foundation of premium customer service by putting you first in all that we do. Our successes are built from simple promises; We want you to own more for less, and we will pay you the most for your metals when you are ready to sell. </div>
          </div>
          <div className='hpboxBox'>
                <div className='hpboxHeader'>The Stacker's Mentality </div>
                <div className='hpboxbody'>We believe in precious metals and what they represent to individual sovereignty and the world stage. We want to offer you the very best experience as a precious metals stacker. Because like you, we are stackers ourselves. We agree with the popular precious metals mantras that have been perpetuated across the globe by many critical-thinkers in this market. We especially admire the following:  "If you don’t hold it, you don’t own it.", "Count your wealth in ounces.",  and the most recent attitude adopted between strangers that caused a critical-mass event in the financial space "Apes together strong." </div>
          </div>
          <div className='hpboxBox'>
                <div className='hpboxHeader'>Best Prices </div>
                <div className='hpboxbody'>Our strategy is simple and it puts you, our customer, as the highest priority; we want you to own more for less. With our partnerships with several of the biggest and acclaimed private mints across the world, we offer you prices for metals that pace us as an industry standard bearer. We want you to buy from us and keep coming back for more because you are being offered discounted prices here at all times! We want you to have the closest experience possible to buying metals at direct wholesale prices. The more we buy, together, the more we all win.</div>
          </div>

        </div>
    </div>
    }
    </div>
  );
}