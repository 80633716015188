import React, { useContext } from 'react';
import Card from '@mui/material/Card';
import CardActions from '@mui/material/CardActions';
import CardContent from '@mui/material/CardContent';
import CardMedia from '@mui/material/CardMedia';
import Typography from '@mui/material/Typography';
import Stack from '@mui/material/Stack';
import Button from '@mui/material/Button';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert ,{ AlertProps } from '@mui/material/Alert';
import { Link, useLocation } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import { getSilverDisc } from '../MainComponents/ProductPage.tsx';
import { SpotContext } from '../../providers/SpotProvider.jsx';
import { CircularProgress, TextField } from '@mui/material';

const Alert = React.forwardRef<HTMLDivElement, AlertProps>(function Alert(
  props,
  ref,
) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

export async function addItemToCart(item) {
  let currentCart = JSON.parse(String(localStorage.getItem("cart")))
  if(item.qty <= 0 || item.qty == 'Nan'){
    return false
  }

  if(currentCart != null && currentCart != undefined && currentCart.length >= 1){
    // we have items in cart 
    // check if current added item is already in cart
    const isItemInCart = currentCart.filter((cartItem)=> cartItem.item.zohoId == item.item.zohoId)
    // if in cart increase quantity 
    if(isItemInCart != null && isItemInCart != undefined && isItemInCart.length != 0){

    if(parseInt(isItemInCart[0].qty) + parseInt(item.qty) > parseInt(item.item.stock)){
      alert("Stock not available. Available : " + parseInt(item.item.stock))
      return false
    }
    
    isItemInCart[0].qty = parseInt(isItemInCart[0].qty) + parseInt(item.qty)
    let newCart = currentCart.filter((cartItem)=> cartItem.item.zohoId != item.item.zohoId)
    newCart.push(isItemInCart[0])
    localStorage.setItem("cart",String(JSON.stringify(newCart)))

    }else{
      if(parseInt(item.qty) > parseInt(item.item.stock)){
        alert("Stock not available. Available : " + parseInt(item.item.stock))
        return false
      }
      let lastid = currentCart[currentCart.length-1].orderitemid
      item["orderitemid"] = 1 + lastid
      item["qty"] = parseInt(item.qty)
      //item["weight"] = 1.09
      currentCart.push(item)
      localStorage.setItem("cart",String(JSON.stringify(currentCart)))
    }
    // else add item to cart with quantity 

  }else{
    if(parseInt(item.qty) > parseInt(item.item.stock)){
      alert("Stock not available. Available: " + parseInt(item.item.stock))
      return false
    }
    item["orderitemid"] = 1
    item["qty"] = parseInt(item.qty)
    //item["weight"] = 1.09
    var newArr = [item]
    localStorage.setItem("cart",String(JSON.stringify(newArr)))
  }
  
}

export default function PLPCard(props) {
  const [open, setOpen] = React.useState(false);
  const [open2, setOpen2] = React.useState(false);
  const [itemPrice, setItemPrice] = React.useState(0);
  const [itemQTY, setItemQTY] = React.useState(1);
  const [cartContainsItem, setCartContainsItem] = React.useState(false);
  const {priceS,priceG,priceP } = useContext(SpotContext);
  const [dealZoneItem, setDealzoneItem] = React.useState(false);
  const [itemAdded, setItemAdded] = React.useState(false);
  const [loadingPrice, setLoadingPrice] = React.useState(true)
  const [hideItem, setHideItem] = React.useState(false)

  const loc = useLocation()

  const item = props.item;
  const itemName = item.name
  const descS = item.descShort
  const itemPremium = item.priceBookRate
  const pricebookType = item.priceBookType
  const itemImage = item.mainImage
  const type = item.category
  const itemId = item?.objectID
  const itemWeight = parseFloat(item?.customField?.cf_silver_content) ?? 1
  const category = item?.category
  const category2 = item?.category2
  const category3 = item?.category3
  const priceDiscount = item?.customField?.cf_quantity_discount
  /*if(item.priceBookRate.length > 0){
    console.log(itemName+":", (item.price + item.priceBookRate[0].pricebook_rate))
  }else{
    console.log(itemName+":", (item.price + item.priceBookRate))
  }*/

  React.useEffect(()=>{
    setDealzoneItem(item?.dealitem == "true")
  },[props.item])

  let itemCalcPrice = 0 

  React.useEffect(()=>{
    let itemCalcPrice = 0

    if(category == "Supplies" || category == "Collectibles"){
      itemCalcPrice = itemPremium
    } else if(pricebookType == "volume" && itemPremium.length > 0){
      let singlePrem = itemPremium[0].pricebook_rate
      itemCalcPrice = parseFloat(parseFloat(itemWeight)*(parseFloat(priceS) + parseFloat(singlePrem))).toFixed(2)
      if(itemPremium.length > 1 && priceDiscount != "null"){
        let bookTop = itemPremium.length-1
        let discount = getSilverDisc(category,category2,category3,bookTop,priceDiscount)
        if(discount == null){
          itemCalcPrice = itemCalcPrice - itemCalcPrice * 1
        }else{
          itemCalcPrice = itemCalcPrice - itemCalcPrice * discount
        }
      }
    }else{
      switch(type){
        case 'Silver' : 
        itemCalcPrice = parseFloat(itemWeight*(priceS + parseFloat(itemPremium))).toFixed(2)
        break;
        case 'Gold' : 
        itemCalcPrice = parseFloat(itemWeight*(priceG + parseFloat(itemPremium))).toFixed(2)
        break;
        case 'Platinum' : 
        itemCalcPrice = parseFloat(itemWeight*(priceP + parseFloat(itemPremium))).toFixed(2)
        break;
      }
    }
    if(priceS != 0 && priceG != 0 && priceP != 0){
    setItemPrice(parseFloat(itemCalcPrice).toFixed(2))
    }
  },[priceS,itemName])
  const delay = ms => new Promise(res => setTimeout(res, ms));

  React.useEffect(()=>{
    setLoadingPrice(false)
  },[itemPrice]);

  React.useEffect(()=>{
    if(Number.isNaN(itemPrice) || itemPrice == "NaN"){
      setHideItem(true)
    }
  },[itemPrice]);



  const handleClick = async () => {
    //setCartContainsItem(true)
    // add item too cart
    let rez = await addItemToCart({...props,qty:itemQTY})
    if(rez != false){
      setOpen(true);
      await delay(3000);
      setOpen(false)
    }
  };
  const handleClick2 = () => {
    setOpen2(true);
    navigator.clipboard.writeText("https://goldguyssilverdev.web.app/")
  };
  const handleClose = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen(false);
  };
  const handleClose2 = (event?: React.SyntheticEvent | Event, reason?: string) => {
    if (reason === 'clickaway') {
      return;
    }

    setOpen2(false);
  };

  function escapeUrl(url) {
    // Escape '/' and space characters in the URL
    const escapedUrl = encodeURIComponent(url).replace(/%20/g, '+').replace(/%2F/g, '/').replaceAll("/","")
    return escapedUrl;
  }

  let prodUrl = '/product/'+escapeUrl(itemName)



  React.useEffect(()=>{
    const items = JSON.parse(localStorage.getItem('cart')) || [];
    for(let i = 0; i < items.length; i++){
      if(items[i].item.zohoId == itemId){
        setCartContainsItem(true)
      }
    }
  },[])
  React.useEffect(()=>{
    setItemQTY(1)

  },[loc])
  
  if(false){
    return (<></>)
  }else{

  return (
    <div className='cardcontain panal' key={props.key}>
      <div style={{height:420}} className=''>
      <Link to={prodUrl} state={{props}}>
      <CardMedia
        sx={{ height:  240}}
        image={itemImage}
        loading="lazy"
        style={{backgroundSize:'contain'}}
        alt={itemName}
      />
      </Link>
      <CardContent>
        <div className='fb'>
        <Typography gutterBottom variant="h5" component="div">
          {loadingPrice || itemPrice == 0 ? <CircularProgress style={{margin:'0px 40px'}}/>:
          <>
            ${itemPrice}
          </>
          }
        </Typography>
        {dealZoneItem ?
        <div className='dealzone'>
          DEAL ZONE
        </div>
        :<></>}</div>
        <Link to={prodUrl} state={{props}}>
        <Typography variant="body1" component="div" style={{marginBottom:5,textDecoration:'underline'}}>
          {itemName}
        </Typography>
        </Link>
        <Typography variant="body2" component="div">
          {descS}
        </Typography>
      </CardContent>
      </div>
      {parseFloat(props.item.stock) > 0 ?
      <CardActions>
        <TextField style={{width:80,height:40,marginRight:25,marginBottom:30,marginTop:20}} type='number' inputProps={{ min: 1 }}
          value={itemQTY}
          onInput={(e)=>{
            setItemQTY(e.target.value)
          }}
        />
        {!open &&
        <Button size="small" onClick={handleClick}>Add To Cart</Button>
        }
        {open ? 
        <Alert variant="filled" severity="success" className='addNotify successfully-saved ' >
        Item Added
      </Alert>
      :<></>}
      </CardActions>
      :
      <div style={{textAlign:'center',padding:40}}>
      Out of stock
      </div>
      }
    </div>
  );
}
}