import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { Link, useNavigate } from 'react-router-dom';

export default function MenuDropDownSilver({setVal,setGoldDOpen,goldDOpen,setPlatDOpen,platDOpen,silvDOpen,setSilverDOpen}) {
      const [open, setOpen] = React.useState(false);
      const anchorRef = React.useRef<HTMLButtonElement>(null);
      const parentDivRef = React.useRef(null);
      const nav = useNavigate()
    
      const handleToggle = () => {
        setSilverDOpen((prevOpen) => !prevOpen);
        setGoldDOpen(false)
        setPlatDOpen(false)
      };


  const closeDrop = (event) =>{
    setSilverDOpen(false)
    setGoldDOpen(false)
    setPlatDOpen(false)
  }
  function openAllSilver(){
    setVal(0)
    nav("/Silver",{state:{ breadCrumb: "/Silver" }} )
  }
  let coincats = [{name:"Silver Coins",bread:'/Silver/Silver-Coins'},{name:"U.S. Silver Coins",bread:'/Silver/Silver-Coins/U.S.-Silver-Coins'}, 
  {name:"Australian Silver Coins",bread:'/Silver/Silver-Coins/Australian-Silver-Coins'}, 
  {name:"Austrian Silver Coins",bread:'/Silver/Silver-Coins/Austrian-Silver-Coins'}, 
  {name:"British Silver Coins",bread:'/Silver/Silver-Coins/British-Silver-Coins'},{name:"Canadian Silver Coins",bread:'/Silver/Silver-Coins/Canadian-Silver-Coins'},{name:"Chinese Silver Coins",bread:'/Silver/Silver-Coins/Chinese-Silver-Coins'},{name:"Mexican Silver Coins",bread:'/Silver/Silver-Coins/Mexican-Silver-Coins'},{name:"Niue Silver Coins",bread:'/Silver/Silver-Coins/Niue-Silver-Coins'},{name:"African Silver Coins",bread:'/Silver/Silver-Coins/African-Silver-Coins'},{name:"Fractional Silver Coins",bread:'/Silver/Silver-Coins/Fractional-Silver-Coins'},
  {name:"Other Silver Coins",bread:'/Silver/Silver-Coins/Other-Silver-Coins'}]
  let silverRoundCats =[{name:"Silver Bars",bread:'/Silver/Silver-Bars'},
  {name:"1 oz Silver Bars",bread:'/Silver/Silver-Bars/1-oz-Silver-Bars'},
  {name:"5 oz Silver Bars",bread:'/Silver/Silver-Bars/5-oz-Silver-Bars'},
  {name:"10 oz Silver Bars",bread:'/Silver/Silver-Bars/10-oz-Silver-Bars'},
  {name:"Kilo Silver Bars",bread:'/Silver/Silver-Bars/Kilo-Silver-Bars'},
  {name:"100 oz Silver Bars",bread:'/Silver/Silver-Bars/100-oz-Silver-Bars'},
  {name:"Other Silver Bars",bread:'/Silver/Silver-Bars/Other-Silver-Bars'},
]
    
      return (
        <Stack direction="row" spacing={2} className='goldBack linkstyle'>
          <div ref={parentDivRef} className='goldBack'>
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={openAllSilver}
              onMouseEnter={handleToggle}
              style={{paddingTop:12,marginRight:10,marginLeft:40}}
              className='goldBack'
            >
              Silver
            </Button>
            <Popper
              open={silvDOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              placement='bottom-start'
              transition
              disablePortal
              style={{background:'transparent',zIndex:10}}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper style={{borderRadius:15,background:'blue'}}>
                    <ClickAwayListener onClickAway={closeDrop}>
                      <div className='fb dropdownmenu'>
                      <div className='toptri fade-up silvertop '></div>
                        <div style={{marginRight:30}}>
                        {coincats.map((item,index)=>{
                          let urll = "/" + item.name.replaceAll(" ", "-")

                        return(
                          <>
                            <Link to={item.bread} state={{ breadCrumb: item.bread}} className={index == 0  ? '':'subMenu'} onClick={()=>{setSilverDOpen(false);setVal(0)}}>
                              {item.name}
                              </Link><br/>
                          </>

                        )
                        })}

                        </div>
                        <div>
                          <Link to="/Silver/Silver-Rounds" state={{ breadCrumb: "/Silver/Silver-Rounds" }} onClick={()=>{setSilverDOpen(false);setVal(0)}}>
                            Silver Rounds
                          </Link><br/>
                        <Link to="/Silver/Fractional-Silver" state={{ breadCrumb: "/Silver/Fractional-Silver" }} onClick={()=>{setSilverDOpen(false);setVal(0)}}>
                            Fractional Silver
                          </Link><br/>
                          {silverRoundCats.map((item,index)=>{
                          let urll = "/" + item.name.replaceAll(" ", "-")

                        return(
                          <>
                            <Link to={item.bread} state={{ breadCrumb: item.bread}} className={index == 0 ? '':'subMenu'} onClick={()=>{setSilverDOpen(false);setVal(0)}}>
                              {item.name}
                              </Link><br/>
                          </>

                        )
                        })}
                        <Link to="/Silver/Junk-Silver" state={{ breadCrumb: '/Silver/Junk-Silver'}} onClick={()=>{setSilverDOpen(false);setVal(0)}}>
                          Junk Silver
                          </Link><br/>
                        </div>
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Stack>
      );
    }