import React from 'react';

const MailchimpForm = () => {
    return (
        <div id="mc_embed_shell">
            <link href="//cdn-images.mailchimp.com/embedcode/classic-061523.css" rel="stylesheet" type="text/css" />
            <style type="text/css">
                {`
                    #mc_embed_signup {
                        clear: left;
                        font: 14px Helvetica, Arial, sans-serif;
                        width: 100%;
                    }
                    /* Add your own Mailchimp form style overrides in your site stylesheet or in this style block.
                    We recommend moving this block and the preceding CSS link to the HEAD of your HTML file. */
                `}
            </style>
            <div id="mc_embed_signup">
                <form
                    action="https://goldguys.us7.list-manage.com/subscribe/post?u=bdc53490f44fbb88b40c38055&amp;id=a227d75c8a&amp;f_id=0061ece4f0"
                    method="post"
                    id="mc-embedded-subscribe-form"
                    name="mc-embedded-subscribe-form"
                    className="validate"
                    target="_blank"
                >
                    <div id="mc_embed_signup_scroll">
                        <h2>NEWSLETTER SIGN-UP</h2>
                        <div className="mc-field-group">
                            <label htmlFor="mce-EMAIL">
                                Email Address <span className="asterisk">*</span>
                            </label>
                            <input type="email" name="EMAIL" className="required email" id="mce-EMAIL" required />
                        </div>
                        <div id="mce-responses" className="clear">
                            <div className="response" id="mce-error-response" style={{ display: 'none' }}></div>
                            <div className="response" id="mce-success-response" style={{ display: 'none' }}></div>
                        </div>
                        <div aria-hidden="true" style={{ position: 'absolute', left: '-5000px' }}>
                            <input type="text" name="b_bdc53490f44fbb88b40c38055_a227d75c8a" tabIndex="-1" value="" />
                        </div>
                        <div className="clear">
                            <input type="submit" name="subscribe" id="mc-embedded-subscribe" className="button" value="Subscribe" style={{background:'#FFC02C',color:'black',fontWeight:'bold'}}/>
                        </div>
                    </div>
                </form>
            </div>
            <script type="text/javascript" src="//s3.amazonaws.com/downloads.mailchimp.com/js/mc-validate.js"></script>
            <script type="text/javascript">
                {`
                    (function($) {
                        window.fnames = new Array(); 
                        window.ftypes = new Array();
                        fnames[0] = 'EMAIL';
                        ftypes[0] = 'email';
                        fnames[1] = 'FNAME';
                        ftypes[1] = 'text';
                        fnames[2] = 'LNAME';
                        ftypes[2] = 'text';
                        fnames[3] = 'MMERGE3';
                        ftypes[3] = 'number';
                        fnames[4] = 'MMERGE4';
                        ftypes[4] = 'address';
                        fnames[5] = 'MMERGE5';
                        ftypes[5] = 'zip';
                        fnames[6] = 'MMERGE6';
                        ftypes[6] = 'birthday';
                        fnames[7] = 'MMERGE7';
                        ftypes[7] = 'number';
                        fnames[8] = 'MMERGE8';
                        ftypes[8] = 'date';
                        fnames[9] = 'MMERGE9';
                        ftypes[9] = 'text';
                        fnames[10] = 'MMERGE10';
                        ftypes[10] = 'number';
                        fnames[11] = 'MMERGE11';
                        ftypes[11] = 'number';
                        fnames[12] = 'MMERGE12';
                        ftypes[12] = 'number';
                    }(jQuery));
                    var $mcj = jQuery.noConflict(true);
                `}
            </script>
        </div>
    );
};

export default MailchimpForm;
