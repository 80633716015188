import * as React from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { convertSecondsToMinutesAndSeconds } from './TImerBlock.tsx';
import { doc, getDoc } from "firebase/firestore";
import { db } from '../../config.tsx';

export default function SpotTicket() {

    const [banners, setBanners] = React.useState([]);


    async function fetchBanners(){
        const docRef = doc(db, "marketing", "bannerinfo");
        const docSnap = await getDoc(docRef);
        
        if (docSnap.exists()) {
          console.log("Document data:", docSnap.data());
          let docArray = docSnap.data().bannerarray || []
            setBanners(docArray)
        } else {
          // docSnap.data() will be undefined in this case
          console.log("No such document!");
        }
    }

    React.useEffect(() => {
        fetchBanners()
    }, []);

    return (
        <div className='scrolling-text' style={{padding:'5px 0px 0px 0px',borderTop:'1px solid',borderBottom:'1px solid',background:'white'}}>
                <marquee className='marqueeback'>
                    <div className='fb' >
                        {banners.map((title)=>{
                            return(
                        <div className='fb' >
                            <div className='bannerText'>
                            {title}
                            </div>
                            <div className='bannerText'>-</div>
                        </div>
                        )})}
                        {banners.map((title)=>{
                            return(
                        <div className='fb' >
                            <div className='bannerText'>
                            {title}
                            </div>
                            <div className='bannerText'>-</div>
                        </div>
                        )})}
                        {banners.map((title)=>{
                            return(
                        <div className='fb' >
                            <div className='bannerText'>
                            {title}
                            </div>
                            <div className='bannerText'>-</div>
                        </div>
                        )})}
                    </div>
                </marquee>
        </div>
    )
}