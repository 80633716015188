import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import Typography from '@mui/material/Typography';
import * as React from 'react';
import HomeIcon from '@mui/icons-material/Home';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ListIcon from '@mui/icons-material/List';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import { Button, ClickAwayListener, Divider, Drawer, Grow, IconButton, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Paper, Popper, TextField } from '@mui/material';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { UserContext } from '../providers/UserProvider.jsx';
import MenuDropDownSilver from './SmallComponents/MenuDropDown.tsx';
import MenuDropDownGold from './SmallComponents/MenuDropDownGold.tsx';
import MenuDropDownPlat from './SmallComponents/MenuDropDownPlat.tsx';
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import LocalShippingIcon from '@mui/icons-material/LocalShipping';
import PermIdentityIcon from '@mui/icons-material/PermIdentity';
import SearchIcon from '@mui/icons-material/Search';
import CacheSpots from './SmallComponents/CacheSpots.tsx';
import Slider from 'react-slick'
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import SearchDisplayComonent from './MainComponents/SearchDisplayComponent.tsx';
import SpotTicket from './SmallComponents/SpotTicket.tsx';
import {Helmet} from "react-helmet";
import { doc, getDoc } from "firebase/firestore";
import { db } from "../config.tsx"

interface TabPanelProps {
  children?: React.ReactNode;
  index: number;
  value: number;
}
type Anchor = 'top' | 'left' | 'bottom' | 'right';

function CustomTabPanel(props: TabPanelProps) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}


export default function TopHeader(props) {
  const user = React.useContext(UserContext);
  const nav = useNavigate()
  const [value, setValue] = React.useState(0);
  const [silverOpen, setSilverOpen] = React.useState(false);
  const [goldOpen, setGoldOpen] = React.useState(false);
  const [platOpen, setPlatOpen] = React.useState(false);
  const [showMarketing, setShowMarketing] = React.useState(false);
  const [searchVal, setSearchVal] = React.useState('');
  const [silvDOpen, setSilvDOpen] = React.useState(false);
  const [goldDOpen, setGoldDOpen] = React.useState(false);
  const [platDOpen, setPlatDOpen] = React.useState(false);
  const [loginOpen, setLoginOpen] = React.useState(false);
  const anchorRef = React.useRef<HTMLButtonElement>(null);
  const [mobileAccount, setMobileAccount] = React.useState('/login');
  const [hasAgreeCookie, setHasAgreeCookie] = React.useState(true);
  const [desktopImages, setDesktopImages] = React.useState([]);
  const [mobileImages, setMobileImages] = React.useState([]);
  function setCookie(name, value, days) {
    const date = new Date();
    date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000)); // Convert days to milliseconds
    const expires = "expires=" + date.toUTCString();
    document.cookie = name + "=" + value + ";" + expires + ";path=/";
}
// Function to delete a cookie by setting its expiration date to the past
function deleteCookie(name) {
  document.cookie = name + "=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}
function getCookie(name) {
  const nameEQ = name + "=";
  const ca = document.cookie.split(';');
  for (let i = 0; i < ca.length; i++) {
      let c = ca[i];
      while (c.charAt(0) === ' ') c = c.substring(1, c.length);
      if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
  }
  return null;
}


// Check if the cookie is older than 30 days
function checkAndDeleteCookie(name) {
  const cookieValue = getCookie(name);
  if (cookieValue) {
      // Parse the stored value and date
      const [value, dateStr] = cookieValue.split('|');
      const setDate = new Date(dateStr);

      // Check if the cookie is older than 30 days
      const now = new Date();
      const daysDifference = (now - setDate) / (1000 * 60 * 60 * 24);
      if (daysDifference > 30) {
          deleteCookie(name);
      } else {
          setHasAgreeCookie(false)
      }
  }
}


  function setAgreeCookie(val){
    setCookie("privacypolicy", val, 30);
    if(val){
      setHasAgreeCookie(false)
    }else{
      setHasAgreeCookie(false)
    }
  }


  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const location = useLocation();

  React.useEffect(()=>{
    setShowMarketing(window.location.pathname === "/")
    checkAndDeleteCookie('privacypolicy')
  },[location])

  // Create a function to update the screenWidth state
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  if(props.selectedHeader != undefined && props.selectedHeader != null){
    setValue(props.selectedHeader)
  }
  React.useEffect(() => {
    // Add an event listener for the "resize" event
    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []); // Empty dependency array to run the effect only once
  
  const handleChange = (event: React.SyntheticEvent, newValue: number) => {
    setValue(newValue);
  };
  const closeDrop = (event) =>{
    setLoginOpen(false)
  }
  const additionalClickHandler = () => {
    setState({ ...state, ['right']: false });
    setSilverOpen(false)
    setGoldOpen(false)
    setPlatOpen(false)
  };
  const handleKeyPress = (event) => {
    if (event.key === 'Enter') {
      nav("/search?search="+searchVal)
      setState({ ...state, ['right']: false });
      setSilverOpen(false)
      setGoldOpen(false)
      setPlatOpen(false)
      setSearchVal("")
    }
  };

  const spotTypes = ["Silver","Gold","Platinum"];

    const [drawer, setDrawerState] = React.useState(false);

    const [state, setState] = React.useState({
      top: false,
      left: false,
      bottom: false,
      right: false,
    });
    function setDraw(type){
      if(type == "Silver"){
        setSilverOpen(!silverOpen)
      }
      if(type == "Gold"){
        setGoldOpen(!goldOpen)
      }
      if(type == "Platinum"){
        setPlatOpen(!platOpen)
      }
    }
    let coincats = [{name:"Silver",bread:'/Silver'},{name:"Silver Coins",bread:'/Silver/Silver-Coins'},{name:"U.S. Silver Coins",bread:'/Silver/Silver-Coins/U.S.-Silver-Coins'}, 
    {name:"Australian Silver Coins",bread:'/Silver/Silver-Coins/Australian-Silver-Coins'}, 
    {name:"Austrian Silver Coins",bread:'/Silver/Silver-Coins/Austrian-Silver-Coins'},
    {name:"British Silver Coins",bread:'/Silver/Silver-Coins/British-Silver-Coins'},{name:"Canadian Silver Coins",bread:'/Silver/Silver-Coins/Canadian-Silver-Coins'},{name:"Chinese Silver Coins",bread:'/Silver/Silver-Coins/Chinese-Silver-Coins'},{name:"Mexican Silver Coins",bread:'/Silver/Silver-Coins/Mexican-Silver-Coins'},{name:"Niue Silver Coins",bread:'/Silver/Silver-Coins/Niue-Silver-Coins'},{name:"African Silver Coins",bread:'/Silver/Silver-Coins/African-Silver-Coins'},{name:"Fractional Silver Coins",bread:'/Silver/Silver-Coins/Fractional-Silver-Coins'},{name:"2 oz Silver Coins",bread:'/Silver/Silver-Coins/2-oz-Silver-Coins'},{name:"5 oz Silver Coins",bread:'/Silver/Silver-Coins/5-oz-Silver-Coins'}, {name:"10 oz Silver Coins",bread:'/Silver/Silver-Coins/10-oz-Silver-Coins'},
  {name:"Other Silver Coins",bread:'/Silver/Silver-Coins/Other-Silver-Coins'}]

    let silverRoundCats =[{name:"Silver Bars",bread:'/Silver/Silver-Bars'},
    {name:"1 oz Silver Bars",bread:'/Silver/Silver-Bars/1-oz-Silver-Bars'},
    {name:"5 oz Silver Bars",bread:'/Silver/Silver-Bars/5-oz-Silver-Bars'},
    {name:"10 oz Silver Bars",bread:'/Silver/Silver-Bars/10-oz-Silver-Bars'},
    {name:"Kilo Silver Bars",bread:'/Silver/Silver-Bars/Kilo-Silver-Bars'},
    {name:"100 oz Silver Bars",bread:'/Silver/Silver-Bars/100-oz-Silver-Bars'},
    {name:"Other Silver Bars",bread:'/Silver/Silver-Bars/Other-Silver-Bars'},]

    let goldcoincats= [{name:"Gold Coins",bread:'/Gold/Gold-Coins/U.S.-Gold-Coins'},{name:"U.S. Gold Coins",bread:'/Gold/Gold-Coins/U.S.-Gold-Coins'}, 
    {name:"Australian Gold Coins",bread:'/Gold/Gold-Coins/Australian-Gold-Coins'}, 
    {name:"Austrian Gold Coins",bread:'/Gold/Gold-Coins/Austrian-Gold-Coins'}, 
    {name:"British Gold Coins",bread:'/Gold/Gold-Coins/British-Gold-Coins'},{name:"Canadian Gold Coins",bread:'/Gold/Gold-Coins/Canadian-Gold-Coins'},{name:"Chinese Gold Coins",bread:'/Gold/Gold-Coins/Chinese-Gold-Coins'},{name:"Mexican Gold Coins",bread:'/Gold/Gold-Coins/Mexican-Gold-Coins'},{name:"African Gold Coins",bread:'/Gold/Gold-Coins/African-Gold-Coins'},{name:"Other Gold Coins",bread:'/Gold/Gold-Coins/Other-Gold-Coins'}]
    
    let goldbarcats =[{name:"Gold Bars",bread:'/Gold/Gold-Bars/-Gold-Bars'},
    {name:"1 oz Gold Bars",bread:'/Gold/Gold-Bars/1-oz-Gold-Bars'},
    {name:"5 oz Gold Bars",bread:'/Gold/Gold-Bars/5-oz-Gold-Bars'},
    {name:"10 oz Gold Bars",bread:'/Gold/Gold-Bars/10-oz-Gold-Bars'},]

    let platcats = [{name:"Platinum",bread:'/Platinum'},
    {name:"Platinum Coins",bread:'/Platinum/Platinum-Coins'},
    {name:"Platinum Bars",bread:'/Platinum/Platinum-Bars'},]
    const handleToggle = () => {
      setLoginOpen((prevOpen) => !prevOpen);
    };
    const toggleDrawer =
      (anchor: Anchor, open: boolean) =>
      (event: React.KeyboardEvent | React.MouseEvent) => {
        setState({ ...state, [anchor]: open });
      };
      const borderRadiusStyle = {
        borderRadius: '30px', // Adjust the radius value as needed
      };

    
    const list = (anchor: Anchor) => (
      <Box
        sx={{ width: anchor === 'top' || anchor === 'bottom' ? 'auto' : 250 }}
        role="presentation"
      >
          <TextField style={{marginRight:20,marginTop:20}} label={"Search Products"} onKeyDown={handleKeyPress}
                      InputProps={{ style: borderRadiusStyle,        endAdornment: (
                        <IconButton onClick={()=>{clicking()}}>
                          <SearchIcon/>
                        </IconButton>
                      ), }}
          value={searchVal}
          onInput={(e) => {
            setSearchVal(e.target.value)
        }}/>        <List>
          {[{name:'Home',url:'/',icon:<HomeIcon/>,link:true},{name:'Cart',url:'/cart',icon:<ShoppingCartIcon/> ,link:true},{name:'Silver',url:'/silver',icon:<AttachMoneyIcon/>}, {name:'Gold',url:'/gold',icon:<AttachMoneyIcon/>}, {name:'Platinum',url:'/platinum',icon:<AttachMoneyIcon/>}, {name:'Deal Zone',url:'/Deals',icon:<AttachMoneyIcon/> ,link:true}, {name:'New Arrivals',url:'/new',icon:<AttachMoneyIcon/> ,link:true}, {name:'Supplies',url:'/supplies',icon:<AttachMoneyIcon/> ,link:true},{name:'Collectibles',url:'/Collectibles',icon:<AttachMoneyIcon/> ,link:true},
          {name:'Account',url:'/login' ,icon:<AttachMoneyIcon/> ,link:true},
          {name:'About',url:'/about',icon:<AttachMoneyIcon/> ,link:true}].map((item, index) => (
           <div>
           <div onClick={()=>{setDraw(item.name)}}>
            <ListItem key={item.name} disablePadding>
              {item.link ? 
              <Link to={item.url} state={{ breadCrumb: item.url}} onClick={additionalClickHandler}>
              <ListItemButton>
              <ListItemIcon>
                {item.icon}
              </ListItemIcon>
              <ListItemText primary={item.name} />
              </ListItemButton>
              </Link>
              :
              
              <ListItemButton>
                <ListItemIcon>
                  {item.icon}
                </ListItemIcon>
                <ListItemText primary={item.name} />
                { item.name == "Silver" && !silverOpen &&
                <ListItemIcon>
                  <KeyboardArrowDownIcon/>
                </ListItemIcon>
                }
                { item.name == "Silver" && silverOpen &&
                <ListItemIcon>
                  <KeyboardArrowUpIcon/>
                </ListItemIcon>
                }
                { item.name == "Gold" && !goldOpen &&
                <ListItemIcon>
                  <KeyboardArrowDownIcon/>
                </ListItemIcon>
                }
                { item.name == "Gold" && goldOpen &&
                <ListItemIcon>
                  <KeyboardArrowUpIcon/>
                </ListItemIcon>
                }
                { item.name == "Platinum" && !platOpen &&
                <ListItemIcon>
                  <KeyboardArrowDownIcon/>
                </ListItemIcon>
                }
                { item.name == "Platinum" && platOpen &&
                <ListItemIcon>
                  <KeyboardArrowUpIcon/>
                </ListItemIcon>
                }
              </ListItemButton>
              }
            </ListItem>
            </div>
            {item.name == "Silver" && silverOpen &&
            <div>
            <div className='dropdownmenu'>
                        <div>
                        { coincats.map((coin, index) => {
                          let urll = "/" + coin.name.replaceAll(" ", "-")
                          return(
                            <>
                            <Link to={coin.bread} state={{ breadCrumb: coin.bread}} className={index == 0 || index == 1 ? '':'subMenu'} onClick={additionalClickHandler}>
                            {coin.name}
                            </Link><br/>
                            </>
                          )
                        })}

                        </div>
                        <div><Link to="/Silver/Silver-Rounds" state={{ breadCrumb: '/Silver/Silver-Rounds'}} onClick={additionalClickHandler}>
                            Silver Rounds
                          </Link><br/>
                          <Link to="/Silver/Fractional-Silver" state={{ breadCrumb: '/Silver/Fractional-Silver'}} onClick={additionalClickHandler}>
                          Fractional Silver
                          </Link><br/>
                          {silverRoundCats.map((coin, index) => {
                          let urll = "/" + coin.name.replaceAll(" ", "-")
                          return(
                            <>
                            <Link to={coin.bread} state={{ breadCrumb: coin.bread}}  className={index == 0 ? '':'subMenu'} onClick={additionalClickHandler}>
                            {coin.name}
                            </Link><br/>
                            </>
                          )
                        })}
                         <Link to="/Silver/Junk-Silver" state={{ breadCrumb: '/Silver/Junk-Silver'}} onClick={additionalClickHandler}>
                          Junk Silver
                          </Link><br/>
                        </div>
                      </div>
            </div>
            }
            {item.name == "Gold" && goldOpen &&
                      <div>
                        <div className='dropdownmenu'>
                        <div>
                        {goldcoincats.map((coin, index) => {
                          let urll = "/" + coin.name.replaceAll(" ", "-")
                          return(
                            <>
                            <Link to={coin.bread} state={{ breadCrumb: coin.bread}}  className={index == 0 ? '':'subMenu'} onClick={additionalClickHandler}>
                            {coin.name}
                            </Link><br/>
                            </>
                          )
                        })}
                      </div>
                      <div>
                      {goldbarcats.map((coin, index) => {
                          let urll = "/" + coin.name.replaceAll(" ", "-")
                          return(
                            <>
                            <Link to={coin.bread} state={{ breadCrumb: coin.bread}} className={index == 0 ? '':'subMenu'} onClick={additionalClickHandler}>
                            {coin.name}
                            </Link><br/>
                            </>
                          )
                        })}
                        </div>
                    </div>
                    </div>
            }
                        {item.name == "Platinum" && platOpen &&
                      <div>
                        <div className='dropdownmenu'>
                        <div>
                        {platcats.map((coin, index) => {
                          let urll = "/" + coin.name.replaceAll(" ", "-")
                          return(
                            <>
                            <Link to={coin.bread} state={{ breadCrumb: coin.bread}} className={index == 0 ? '':'subMenu'} onClick={additionalClickHandler}>
                            {coin.name}
                            </Link><br/>
                            </>
                          )
                        })}
                        </div>
                        </div>
                      </div>
                        }
            </div>
          ))}
        </List>
        <Divider />
      </Box>
    );
    const settings = {
      dots: true,
      speed: 2000,
      slidesToShow: 1,
      slidesToScroll: 1,
      infinite: true,
      autoplay: true,
      autoplaySpeed: 3000,
    };
  async function fetchImagers(){
      const docRef = doc(db, "marketing", "homepageMarketing");
      const docSnap = await getDoc(docRef);
      
      if (docSnap.exists()) {

        setMobileImages(docSnap.data().topImageCarArrayMobile )
        setDesktopImages(docSnap.data().topImageCarArray)
      } else {
        // docSnap.data() will be undefined in this case
        console.log("No such document!");
      }
  }
  React.useEffect(()=>{
    fetchImagers()
  },[])

  function clicking(){
    nav("/search?search="+searchVal)
    setState({ ...state, ['right']: false });
    setSilverOpen(false)
    setGoldOpen(false)
    setPlatOpen(false)
    setSearchVal("")
  }

  return (
    <div className='defaultBackgoundOVer' style={{backgroundRepeat:'repeat',width:'100%'}}>
          <div>
      {(['right'] as const).map((anchor) => (
        <React.Fragment key={anchor}>
          <Drawer
            anchor={anchor}
            open={state[anchor]}
            onClose={toggleDrawer(anchor, false)}
          >
            {list(anchor)}
          </Drawer>
        </React.Fragment>
      ))}
    </div>
    <div className='headerHold'>
    <div className="fb fgrey">
    {screenWidth > 1058 ?
    <div className='fb fgrey'>
    <Link to="/" onClick={()=>{setValue(0)}} className='fgrey'>
            <Button style={{padding:0}}>        
              <img src="/static/images/4.png" alt="Gold Guys Bullion" width={100} height={100} style={{marginTop:-10,marginBottom:-10,background:'transparent'}} className='fgrey'/>
        </Button>
          </Link>
    <div className='fgrey fontS' style={{textWrap:'nowrap',margin:'auto'}}><b>Free Shipping</b><br/>on Orders $199+</div>

    </div>
    :<></>}
    <div className='fb space_around fgrey spotCont'>
      <CacheSpots/>
    </div>
    { screenWidth > 1058  && <TextField className='fgrey' style={{width:300,marginRight:20,marginTop:10}} placeholder='Search Products' onKeyDown={handleKeyPress}
                      InputProps={{ style: borderRadiusStyle,        endAdornment: (
                        <IconButton onClick={()=>{clicking()}}>
                          <SearchIcon/>
                        </IconButton>
                      ), }}
          value={searchVal}
          onInput={(e) => {
            setSearchVal(e.target.value)
        }}/>}

    </div>
    <div>
      <SpotTicket/>
    </div>
    {screenWidth > 1058 ? 
    <div style={{ width: '100%' }} className='goldBack'>
      <Box sx={{ borderBottom: 1, borderColor: 'divider'}} className='goldBack'>
        <Tabs value={value} onChange={handleChange} style={{overflow:'visible'}} className='goldBack'>
          <MenuDropDownSilver setVal={setValue} setPlatDOpen={setPlatDOpen} platDOpen={platDOpen} setGoldDOpen={setGoldDOpen} goldDOpen={goldDOpen} silvDOpen={silvDOpen} setSilverDOpen={setSilvDOpen}/>
          <MenuDropDownGold setVal={setValue} setSilvDOpen={setSilvDOpen} setPlatDOpen={setPlatDOpen} silvDOpen={silvDOpen} platDOpen={platDOpen} goldDOpen={goldDOpen} setGoldDOpen={setGoldDOpen}/>
          <MenuDropDownPlat setVal={setValue} setGoldDOpen={setGoldDOpen} setSilvDOpen={setSilvDOpen} goldDOpen={goldDOpen} silvDOpen={silvDOpen} platDOpen={platDOpen} setPlatDOpen={setPlatDOpen}/>
          <Link to='/Deals' state={{ breadCrumb: '/Deals'}}  className="linkstyle goldBack">
          <Tab label="Deal Zone"  onClick={()=>{setValue(3 )}} />
          </Link>
          <Link to='/new' state={{ breadCrumb: '/New-Arrivals'}}  className="linkstyle goldBack">
          <Tab label="New Arrivals" onClick={()=>{setValue(4)}}/>
          </Link>
          <Link to='/Supplies'  state={{ breadCrumb: '/supplies'}} className="linkstyle goldBack">
          <Tab label="Supplies" onClick={()=>{setValue(5)}}/>
          </Link>
          <Link to='/Collectibles' state={{ breadCrumb: '/collectibles'}}  className="linkstyle goldBack">
          <Tab label="Collectibles" onClick={()=>{setValue(6)}}/>
          </Link>
          <Link to='/about' state={{ breadCrumb: '/about'}}  className="linkstyle goldBack">
          <Tab label="About" onClick={()=>{setValue(7)}}/>
          </Link>
          <div style={{ marginLeft: 'auto',marginTop:5,display:'flex' }} className='goldBack'>
          <div className='linkstyle goldBack' style={{fontSize:25,marginRight:20}}>||</div>
          <Link to={user ? "/myaccount": "/login"} className='linkstyle goldBack'>
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-haspopup="true"
              className='goldBack'
            >
              {user ? "My Account": "login"} <PermIdentityIcon className='blackIcon'/>
            </Button>
          </Link>
          <Link to="/cart" className='linkstyle goldBack'>
            <Button><ShoppingCartIcon className='blackIcon'/></Button>
          </Link>
          </div>


        </Tabs>
      </Box>
    </div>
    : <></>}
    </div>
    { screenWidth > 1058 ? <div className='fb jsb horMarg20 headHeight'></div>:
    <div className='fb jsb horMarg20' style={{height:100}}></div>
    }
    {screenWidth < 1058 ?
    <>
      <div className="mobileIconLeft">
        <Link to="/" className='imageLinkBack'>
          <img src="/static/images/4.png" alt="Gold Guys Bullion" width="80" height="80" />
          </Link>
                  </div>
      <div className="mobiledraw">
        <ListIcon className='mobiledrawicon' style={{height:60,width:60}} onClick={toggleDrawer('right', true)}/>
      </div>
      </>
      :<></>
    }{ showMarketing &&
        <div>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Gold Guys Bullion</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Gold Guys Bullio"/>
            <meta name="keywords" content="Silver, Gold, Tradding, Bars, Coins"/>
        </Helmet>
        <div className='background-overlay homeHeaderBackgourd w100' 
              style={{backgroundImage: `url(/static/images/GoldGuysVar2.png)`}}
        >
          <div className='homePageCustImagCont'>
          <img className='homePageCustImag' src="/static/images/OMFL3.png" width={150} height={150} />
          </div>
        </div>
        <div className='greyHor'>
        </div>
        <div>
              <div style={{margin:30}}>
              {screenWidth > 1058 ?
              <Slider {...settings}>
                {desktopImages.map((item)=>{
                  if(item.imageUrl == ""){
                    return(<></>)
                  }else if(item.linkUrl != ""){
                    return(
                    <div>
                    <a href={item.linkUrl} target='_blank'>
                    <img src={item.imageUrl} style={{maxWidth:1500,width:'100%',display:'flex',justifyContent:'center',margin:'auto'}} height={600} alt="Gold Guys Bullion"/>
                    </a>
                  </div>
                    )
                  }else{
                    return(
                      <div>
                      <img src={item.imageUrl} style={{maxWidth:1500,width:'100%',display:'flex',justifyContent:'center',margin:'auto'}} height={600} alt="Gold Guys Bullion"/>
                    </div>
                    )
                  }
                })}

                </Slider>
                :
                <Slider {...settings}>
                {mobileImages.map((item)=>{
                  if(item.imageUrl == ""){
                    return(<></>)
                  }else if(item.linkUrl != ""){
                    return(
                    <div>
                    <a href={item.linkUrl} target='_blank'>
                    <img src={item.imageUrl} style={{width:'100%'}} height={500} alt="Gold Guys Bullion"/>
                    </a>
                  </div>
                    )
                  }else{
                    return(
                      <div>
                      <img src={item.imageUrl} style={{width:'100%'}} height={500} alt="Gold Guys Bullion"/>
                    </div>
                    )
                  }
                })}

                </Slider>
                }
              </div>
              <div>
              </div>
        </div>
        <div style={{display:'flex',justifyContent:'center',margin:30}}>
        { user ? <></> :<>
        <hr/>
        <Link to="/createaccount">
          <Button variant="contained" className='goldButton'>Create An Account</Button>
        </Link>
        </>
        }
        </div>
        <hr/>
        <SearchDisplayComonent categoryDisplay="Deal Zone" limitRes={true}/>
        <div className='fb fw'
                      
                      >
          <div className='hpboxBox'>
                <div className='hpboxHeader'>Accredited Business</div>
                <div className='hpboxbody'>For over 15 years The Gold Guys have been an industry leader in operational excellence and a pioneer of innovation within the precious metals market. With over 300,0000 transactions with tens of thousands of happy customers we have built a foundation of premium customer service by putting you first in all that we do. Our successes are built from simple promises; We want you to own more for less, and we will pay you the most for your metals when you are ready to sell. </div>
          </div>
          <div className='hpboxBox'>
                <div className='hpboxHeader'>The Stacker's Mentality </div>
                <div className='hpboxbody'>We believe in precious metals and what they represent to individual sovereignty and the world stage. We want to offer you the very best experience as a precious metals stacker. Because like you, we are stackers ourselves. We agree with the popular precious metals mantras that have been perpetuated across the globe by many critical-thinkers in this market. We especially admire the following:  "If you don’t hold it, you don’t own it.", "Count your wealth in ounces.",  and the most recent attitude adopted between strangers that caused a critical-mass event in the financial space "Apes together strong." </div>
          </div>
          <div className='hpboxBox'>
                <div className='hpboxHeader'>Best Prices </div>
                <div className='hpboxbody'>Our strategy is simple and it puts you, our customer, as the highest priority; we want you to own more for less. With our partnerships with several of the biggest and acclaimed private mints across the world, we offer you prices for metals that pace us as an industry standard bearer. We want you to buy from us and keep coming back for more because you are being offered discounted prices here at all times! We want you to have the closest experience possible to buying metals at direct wholesale prices. The more we buy, together, the more we all win.</div>
          </div>

        </div>
    </div>
    }
    { hasAgreeCookie && (
        <div id="privacy-policy">
          <p>Do you agree to our cookie and <Link to="/privacy" style={{textDecoration:'underline'}}>privacy policy</Link>?</p>
          <button id="agree-button" onClick={()=>{setAgreeCookie(true)}}>Agree</button>
          <button id="disagree-button" onClick={()=>{setAgreeCookie(true)}}>Disagree</button>
      </div>
    )
  }
    </div>
  );
}