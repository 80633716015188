import * as React from 'react';
import { formatPrice } from './CartPage.tsx';
import { useCheckout } from '../../providers/CheckoutProvider.jsx';
import {Helmet} from "react-helmet";

export default function OrderCompletePage() {

    var shippingLabel1= "Credit Card"
    var shippingLabel2= "PayPal"
    var shippingLabel3= "ECheck"
    var image = "/static/images/goldbaby.jpeg";
    const [cartItems, setCartItems] = React.useState([])
    const [finalTotal, setFinalTotal] = React.useState(0)

    const [finalAddressObject, setFinalAddressObject] = React.useState({});
    const { continueDisableButton,getDisableContinue,getAddress, getShippingPrice ,getCartSubTotal,getCartFinalTotal,cartSubTotal,shippingPriceData,getTax,setCartSubTotal,getPayTypeObj,getCart,setSubTotal,setShippingPrice,setCartFinalTotal,setTax } = useCheckout();

    function createData(
        name: string,
        price: number,
        quantity:number
      ) {
        return { name, price };
      }
      React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])

      async function getAddressObj(){
        var address = JSON.parse(String(localStorage.getItem("shippingAddress")))
        setFinalAddressObject(address)

      }

      async function getCartItems(){
        var cartV = JSON.parse(String(localStorage.getItem("cart")))
        if(cartV != null){
          setCartItems(cartV)
          let total = 0
          for(let i = 0 ; i < cartV.length; i++){
            total = total + parseFloat(cartV[i].calculatedSinglePrice) * parseInt(cartV[i].qty)
          }

          var ship = JSON.parse(String(localStorage.getItem("shippingPrice")))
          var tax = parseFloat(String(localStorage.getItem("taxCalc")))


          total = total + ship + tax
          //setFinalTotal(total)
        }else{
          return []
        }
      }
      React.useEffect(() => {
        //getCartItems()
        getAddressObj()
        setCartSubTotal(0)
        setShippingPrice(0)
        setCartFinalTotal(0)
        setTax(0)
        let lastFinal = JSON.parse(String(localStorage.getItem("lastcarttotal")))
        let lastFinalCart = JSON.parse(String(localStorage.getItem("lastCartOrder")))

        console.log("HERERE last:")
        setFinalTotal(lastFinal)
        setCartItems(lastFinalCart)
        console.log(lastFinal)
        console.log(lastFinalCart)

        localStorage.setItem('cart', JSON.stringify([]));
        localStorage.setItem('tax', JSON.stringify(""));
        localStorage.setItem('shippingPrice', JSON.stringify(""));

      }, [])
    return (
        <div className='defPageMarg'>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Order Complete</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Order Complete"/>
            <meta name="keywords" content="Silver, Gold, Tradding, Bars, Coins"/>
        </Helmet>
            <div style={{textAlign:'center',fontSize:25,marginTop:65}}>
                ORDER COMPLETE. Check Email for invoice and next steps. Thank you.
            </div>
            <div style={{margin:50}}>
            <div className='defaultTitle'>Order Details</div>
                <div style={{marginBottom:5}}>Ship to:</div>
                <div className='fb jsb'>
                <b>{finalAddressObject.addrline1} {finalAddressObject.addrline2} {finalAddressObject.addrcity}, {finalAddressObject.addrstate} {finalAddressObject.addrZip}</b>
                <b>Order Total: ${formatPrice(finalTotal)}</b>
                </div>
                <br/>
                <div>
                    <div>
                        Order Items
                    </div>
                    {cartItems.map((row) => (
                                <div>
                                    <hr/>
                                    <div className='fb'>
                                        <div>
                                            <img src={row?.item.mainImage} alt={row?.item.name} height="70" width="70" style={{marginRight:15}}/>
                                        </div>
                                        <div>
                                        <div>
                                            {row.item.name}
                                            </div>
                                            <div> <b>${formatPrice(row.calculatedSinglePrice)} X {row.qty}</b></div>
                                        </div>
                                        
                                    </div>
                                    
                                </div>
                                ))}
                </div>
            </div>

        </div>
    )
}