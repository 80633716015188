import { Button, CircularProgress } from '@mui/material';
import * as React from 'react';
import { Link } from 'react-router-dom';
import { getFunctions, httpsCallable } from "firebase/functions";
import {Helmet} from "react-helmet";

export default function MyOrders() {
    const functions = getFunctions();
    const [openRowIndex, setOpenRowIndex] = React.useState(null)
    const [rows, setRows] = React.useState([])
    const [isLoading,setLoading] = React.useState(true)

    function createData(
        name: string,
        price: number,
        quantity:number
      ) {
        return { name, price };
      }
      var image = "/static/images/goldbaby.jpeg";


      React.useEffect(()=>{
        try{
            const addMessage = httpsCallable(functions, 'myOrderCall');
                addMessage()
                .then((result) => {
                    setRows(result.data.salesOrder.invoices)
                    setLoading(false)
                });
            }catch(e){
                console.log(e)
            }
      },[])

      if(isLoading){
        return(
            <div style={{height:350,display:'flex',justifyContent:'center',paddingTop:100}}>
                <CircularProgress/>
            </div>
        )
      }
  return (
    <div className='mainpageblock' style={{marginTop:100}}>
        <Helmet>
            <meta charSet="utf-8" />
            <title>My Orders</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="My Orders"/>
            <meta name="keywords" content="Silver, Gold, Tradding, Bars, Coins"/>
        </Helmet>
        <h3 style={{textAlign:'center'}}>
            My Orders
        </h3>
        {rows.length == 0 && (<div style={{textAlign:'center',marginBottom:50}}>No orders have been made on this account.</div>)}
                    {rows.map((row,index) => (
                        <div key={index}>
                        <hr/>
                        <div className='fb jsb'>
                            <div>
                                <div style={{margin:'auto 20px auto 0px'}}>
                                    Invoice Number: {row.invoice_number} 
                                </div>
                                <div>
                                    Status : {row.status} 
                                </div>
                                <div>
                                    Due Date: {row.due_date}
                                </div>
                                <div>Amount Due: ${parseFloat(row.balance).toFixed(2)}</div>
                            </div>
                            <div>
                                <Link to={row.invoice_url} target='_blank'>
                                <Button variant="outlined">Invoice Details</Button>
                                </Link><br/>
                                {/*<Link target='_blank'>
                                <Button variant="outlined">Tracking Details</Button>
                                </Link>*/}
                            </div>
                        </div>
                        
                    </div>
                        ))}
    </div>
  );
}