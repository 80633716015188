import * as React from 'react';
import { useTimer } from '../../providers/TImeProvider.jsx';
import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton } from '@mui/material';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { useLocation, useNavigate } from 'react-router-dom';

export function TimerBlock(){
    const location = useLocation()
    let currentRemain = 300
    if(!location.pathname.includes("cart")){
        currentRemain = parseInt(sessionStorage.getItem("remainingTime"))
    }
    const [open, setOpen] = React.useState(false);
    const [seconds,setSeconds] = React.useState(currentRemain)
    const nav = useNavigate()

    React.useEffect(() => {
        const handle = setInterval(() => {
            setSeconds(seconds => seconds - 1);
        }, 1000);
        return ()=>{
          clearInterval(handle)
        }
    },[])
    React.useEffect(()=>{
        sessionStorage.setItem("remainingTime",String(seconds))
        if(seconds <= 0){
            alert("Reset Cart")
            nav("/cart")
            setSeconds(300)
        }
    },[seconds])

    const handleClickOpen = () => {
        setOpen(true);
      };
      const handleClose = () => {
        setOpen(false);
      };
    return(
        <div>
                      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">
          {"Attention:"}
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
          Timer resets when you leave this page. Your shopping cart requires recalibration due to recent fluctuations in spot prices. Please review your selections as the costs may have been adjusted to reflect the changes in pricing. Your cart will need to be updated accordingly to ensure accurate billing and order fulfillment.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} autoFocus>
            Close
          </Button>
        </DialogActions>
      </Dialog>
        You have 5 minutes to complete your order.
        <IconButton onClick={handleClickOpen}>
        <HelpOutlineIcon/>
        </IconButton>
        Timer : {convertSecondsToMinutesAndSeconds(seconds)}
      </div>
    )
}
export function convertSecondsToMinutesAndSeconds(seconds) {
    // Calculate minutes
    var minutes = Math.floor(seconds / 60);
  
    // Calculate remaining seconds
    var remainingSeconds = seconds % 60;
    if(remainingSeconds < 10 ){
      return minutes+":0"+remainingSeconds
    }else{
      return minutes+":"+remainingSeconds
    }
  
  }