import logo from './logo.svg';
import './App.css';
import DesktopHome from './Component/Desktop/DesktopHome.tsx';
import { BrowserRouter, Routes, Route } from "react-router-dom";
import Login from './Component/Login.tsx';
import { ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';
import Footer from './Component/Footer.tsx';
import CartPage from './Component/MainComponents/CartPage.tsx';
import ShippingPage from './Component/MainComponents/ShippingPage.tsx';
import ReviewCompletePage from './Component/MainComponents/ReviewPage.tsx';
import OrderCompletePage from './Component/MainComponents/OrderComplete.tsx';
import HelpPage from './Component/MainComponents/HelpPage.tsx';
import TopHeader from './Component/Header.tsx';
import SearchDisplayComonent from './Component/MainComponents/SearchDisplayComponent.tsx';
import SellPage from './Component/MainComponents/SellPage.tsx';
import ProductPage from './Component/MainComponents/ProductPage.tsx';
import CreateAccount from './Component/MainComponents/CreateAccount.tsx';
import UserPage from './Component/MainComponents/UserPage.tsx';
import UserProvider from './providers/UserProvider';
import MyOrders from './Component/MainComponents/MyOrders.tsx';
import SiteMapGen from './Component/SiteMapGen.tsx';
import AboutPage from './Component/MainComponents/AboutPage.tsx';
import { SpotContextProvider } from './providers/SpotProvider';
import Terms from './Component/SmallComponents/Terms.tsx';
import { TimerProvider } from './providers/TImeProvider.jsx';
import { CheckoutProvider } from './providers/CheckoutProvider.jsx';
import Privacy from './Component/SmallComponents/Privacy.tsx';
import ShutDown from './Component/MainComponents/ShutDown.tsx';
import * as React from 'react';
import { db } from './config.tsx';
import { doc, updateDoc ,getDoc} from "firebase/firestore";
import OutOfUS from './Component/MainComponents/OutOfUS.tsx';
import OptoutURL from './Component/MainComponents/OptOutURL.tsx';


const isDarkMode = () => 
  window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
var theme = null;
  if( isDarkMode){
  theme = createTheme({
  palette: {
    mode: 'light',
  },
});
  }else{
    theme = createTheme({
      palette: {
        mode: 'light',
      },
  })
}



export default function App() {
  const [shutdown, setShutDown] = React.useState(false);
  const [country, setCountry] = React.useState("US");

  async function fetchShutdown(){
    const docRef = doc(db, "marketing", "siteShutdown");
    const docSnap = await getDoc(docRef);
    console.log("--------------sit res")
    console.log(docSnap.data())
    let res = docSnap.data().shutdown
    setShutDown(res)
  }
  React.useEffect(()=>{
    fetch('https://ipinfo.io/json')
    .then(response => response.json())
    .then(data => {
      // Extract the country from the response
      const country = data.country;
      console.log('User\'s country:', country);
      // You can do whatever you want with the country here
    })
    .catch(error => {
      console.log('Error:', error);
    });
  },[])
  React.useEffect(()=>{
    let fetch = fetchShutdown()
  },[])
  if(country != "US"){
    return <OutOfUS/>
  }
  console.log("--------------")
  console.log(shutdown)

  if(shutdown){
    return (
      <CheckoutProvider>
      <UserProvider>
      <SpotContextProvider>
      <BrowserRouter>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <Routes >
          </Routes>
      <ShutDown/>
    </ThemeProvider>
    </BrowserRouter>
    </SpotContextProvider>
    </UserProvider>
    </CheckoutProvider>
    )
  }else{
  
  return (
    <CheckoutProvider>
    <UserProvider>
    <SpotContextProvider>
    <BrowserRouter>
    <ThemeProvider theme={theme}>
      <CssBaseline />
      <TopHeader/>
      <Routes >
          <Route index element={<DesktopHome/>} />
          <Route path="/:param1"  element={<SearchDisplayComonent categoryDisplay="silver"/>} />
          <Route path="/silver"  element={<SearchDisplayComonent categoryDisplay="silver"/>} />
          <Route path="/search"  element={<SearchDisplayComonent categoryDisplay="Search"/>} />
          <Route path="/silver/:param1"  element={<SearchDisplayComonent categoryDisplay="silver"/>} />
          <Route path="/silver/:param1/:param2"  element={<SearchDisplayComonent categoryDisplay="silver"/>} />
          <Route path="/gold"  element={<SearchDisplayComonent categoryDisplay="gold"/>} />
          <Route path="/gold/:param1"  element={<SearchDisplayComonent categoryDisplay="gold"/>} />
          <Route path="/gold/:param1/:param2"  element={<SearchDisplayComonent categoryDisplay="gold"/>} />
          <Route path="/platinum"  element={<SearchDisplayComonent categoryDisplay="platinum"/>} />
          <Route path="/platinum/:param1"  element={<SearchDisplayComonent categoryDisplay="platinum"/>} />
          <Route path="/platinum/:param1/:param2"  element={<SearchDisplayComonent categoryDisplay="platinum"/>} />
          <Route path="/Deals"  element={<SearchDisplayComonent categoryDisplay="Deal Zone"/>} />
          <Route path="/new"  element={<SearchDisplayComonent categoryDisplay="New Arrivals"/>} />
          <Route path="/supplies"  element={<SearchDisplayComonent categoryDisplay="supplies"/>} />
          <Route path="/sell"  element={<SellPage/>} />
          <Route path="/login" element={<Login />} />
          <Route path="/cart" element={<CartPage/>}/>
          <Route path="/shipping" element={<ShippingPage/>}/>
          <Route path="/review" element={<ReviewCompletePage/>}/>
          <Route path="/ordercomplete" element={<OrderCompletePage/>}/>
          <Route path="/help" element={<HelpPage/>}/>
          <Route path="/product/:param1" element={<ProductPage/>}/>
          <Route path="/createaccount" element={<CreateAccount/>}/>
          <Route path="/myaccount" element={<UserPage/>}/>
          <Route path="/myorders" element={<MyOrders/>}/>
          <Route path="/about" element={<AboutPage/>}/>
          <Route path="/terms" element={<Terms/>}/>
          <Route path="/privacy" element={<Privacy/>}/>
          <Route path="/opt-out" element={<OptoutURL/>}/>
      </Routes>
    <Footer/>
    </ThemeProvider>
    </BrowserRouter>
    </SpotContextProvider>
    </UserProvider>
    </CheckoutProvider>
  );
}
}