import AttachMoneyIcon from '@mui/icons-material/AttachMoney';
import { Button, Paper, TextField } from '@mui/material';
import BottomNavigation from '@mui/material/BottomNavigation';
import BottomNavigationAction from '@mui/material/BottomNavigationAction';
import { MDBCol, MDBContainer, MDBFooter, MDBIcon, MDBRow } from 'mdb-react-ui-kit';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import HomeIcon from '@mui/icons-material/Home';
import HelpCenterIcon from '@mui/icons-material/HelpCenter';
import ShoppingCartIcon from '@mui/icons-material/ShoppingCart';
import MailchimpForm from './SmallComponents/MailChimp.tsx';

export default function App() {
  const [value, setValue] = React.useState(0);
  const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
  const [emailValue, setEmailValue] = React.useState("");

  // Create a function to update the screenWidth state
  const updateScreenWidth = () => {
    setScreenWidth(window.innerWidth);
  };

  React.useEffect(() => {
    // Add an event listener for the "resize" event
    window.addEventListener('resize', updateScreenWidth);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', updateScreenWidth);
    };
  }, []); // Empty dependency array to run the effect only once
  function formatDate() {
    const currentDate = new Date();
  
    const month = (currentDate.getMonth() + 1).toString().padStart(2, '0');
    const day = currentDate.getDate().toString().padStart(2, '0');
    const year = currentDate.getFullYear().toString().slice(-2);
  
    return `${month}/${day}/${year}`;
  }
  //

    async function sendMailChimp(){
      const apiKey = '46a1cad4278f02aadadbca920734e5fa-us7';
      const audienceId = 'a227d75c8a';
      
      const url = 'http://mailchimp.us8.list-manage.com/subscribe/post?u=46a1cad4278f02aadadbca920734e5fa-us7&id=a227d75c8a';
    
      const formData = new FormData();
      formData.append('EMAIL', emailValue);
  
      const requestOptions = {
        method: 'POST',
        body: formData,
        mode: 'no-cors'
      };
  
      try {
        const response = await fetch(url, requestOptions);
        if (response.ok) {
          console.log(response)
        } else {
          console.log(response)
        }
      } catch (error) {
        console.log(error)
      }
      
    }


  return (
    <MDBFooter className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>
        <div>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="facebook-f" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="twitter" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="google" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="instagram" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="linkedin" />
          </a>
          <a href='' className='me-4 text-reset'>
            <MDBIcon fab icon="github" />
          </a>
        </div>
      </section>

      <section className=''>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
          <MDBCol md="1" lg="1" xl="1" className='mx-auto mb-4'>
          <img src="/static/images/4.png" alt="Gold Guys Bullion" width={80} height={80}/>

          </MDBCol>
          <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Useful links</h6>
              <p>
              <Link to="/myaccount">
                  My Account
                </Link>
              </p>
              <p>
              <Link to="/help">
                  Help
                  </Link>
              </p>
              <p>
              <Link to="/terms">
                  Terms
                  </Link>
              </p>
              <p>
              <Link to="/privacy">
                  Privacy
                  </Link>
              </p>
              <p>
              <Link to="https://www.goldguys.com/contact/">
                  Contact Us
                  </Link>
              </p>
            </MDBCol>
            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <Link to="/gold">
                Gold
                </Link>
              </p>
              <p>
                <Link to="/silver">
                Silver
                </Link>
              </p>
              <p>
              <Link to="/platinum">
              Platinum
                </Link>
              </p>
            </MDBCol>

            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
            </MDBCol>
            <MDBCol md="5" lg="4" xl="3" className='mx-auto mb-4'>
              <div>
                <div>
                  <div>
                    <MailchimpForm/>
                  </div>
                </div>
              </div>
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <div style={{textAlign:'center',marginBottom:30}}>
          Search Powered By Algolia
        </div>
      <div style={{marginLeft:20}}>Servicing US Domestic orders only. No International Services are offered at this time</div>
      {screenWidth > 500 &&<div className='fb sa' style={{padding:50}}>
        <Link to="https://www.bbb.org/us/mn/woodbury/profile/precious-metal-dealers/the-gold-guys-0704-96119200/" target='_blank'>
        <div className='fb'>
        <img src="/static/images/bbbfoot.png" alt="Gold Guys Bullion Rating"/>
        <div>
          <div className='smallTextFoot'>BBB Rating A+</div>
          <div className='smallTextFoot'>As of {formatDate()}<br/>Click for Profile</div>
        </div>
        </div>
        </Link>
        <div style={{textAlign:'center'}}>
          <a href="">Terms & Conditions / Privacy </a> | <a href=""> Sitemap </a> |  2023 The Gold Guys®. All Rights Reserved.Trademark registered with the US Patent and Trademark Office. Responsive Website Design by ECCO Development.
        </div>
      </div>}
      {screenWidth < 500 &&<div style={{padding:50}}>
        <div style={{padding:20}}>
        <img src="/static/images/bbbfoot.png" alt="Gold Guys Bullion Rating"/>
        </div>
        <div style={{textAlign:'center',marginBottom:30}}>
          <a href="">Terms & Conditions / Privacy </a> | <a href=""> Sitemap </a> |  2023 The Gold Guys®. All Rights Reserved.Trademark registered with the US Patent and Trademark Office. Responsive Website Design by ECCO Development.
        </div>
      </div>}
      {screenWidth < 500 && (
      <Paper sx={{ position: 'fixed', bottom: 0, left: 0, right: 0, zIndex:2 }} elevation={3}>
      <BottomNavigation
        showLabels
        
        value={value}
        onChange={(event, newValue) => {
          setValue(newValue);
        }}
      >
        <BottomNavigationAction component={Link} to="/" label="Home" icon={<HomeIcon />} />
        <BottomNavigationAction component={Link} state={{ breadCrumb: '/Gold'}} to="/gold" label="Gold" icon={<AttachMoneyIcon />} />
        <BottomNavigationAction component={Link} state={{ breadCrumb: '/Silver'}} to="/silver" label="Silver" icon={<AttachMoneyIcon />} />
        <BottomNavigationAction component={Link} to="/cart" label="Cart" icon={<ShoppingCartIcon />} />
      </BottomNavigation>
    </Paper>
      )}
    </MDBFooter>
    
  );
}