import * as React from 'react';
import Button from '@mui/material/Button';
import ClickAwayListener from '@mui/material/ClickAwayListener';
import Grow from '@mui/material/Grow';
import Paper from '@mui/material/Paper';
import Popper from '@mui/material/Popper';
import Stack from '@mui/material/Stack';
import { Link, useNavigate } from 'react-router-dom';

export default function MenuDropDownPlat({setVal,setGoldDOpen,goldDOpen,setSilvDOpen,silvDOpen,platDOpen,setPlatDOpen}) {
      const [open, setOpen] = React.useState(false);
      const anchorRef = React.useRef<HTMLButtonElement>(null);
      const parentDivRef = React.useRef(null);
      const nav = useNavigate()

      const handleToggle = () => {
        setPlatDOpen((prevOpen) => !prevOpen);
        setGoldDOpen(false)
        setSilvDOpen(false)
      };
      function openAllPlat(){
        nav("/Platinum",{state:{ breadCrumb: "/Platinum" }} )
        setVal(2)
      }

  const closeDrop = (event) =>{
    setPlatDOpen(false)
    setGoldDOpen(false)
    setSilvDOpen(false)
  }
  let platcats = [{name:"Platinum",bread:'/Platinum'},
  {name:"Platinum Coins",bread:'/Platinum/Platinum-Coins'},
  {name:"Platinum Bars",bread:'/Platinum/Platinum-Bars'},]
    
      return (
        <Stack direction="row" spacing={2} className='goldBack linkstyle'>
          <div ref={parentDivRef} className='goldBack'>
            <Button
              ref={anchorRef}
              id="composition-button"
              aria-controls={open ? 'composition-menu' : undefined}
              aria-expanded={open ? 'true' : undefined}
              aria-haspopup="true"
              onClick={openAllPlat}
              onMouseEnter={handleToggle}
              style={{paddingTop:12,marginRight:10}}
              className='goldBack'
            >
              Platinum
            </Button>
            <Popper
              open={platDOpen}
              anchorEl={anchorRef.current}
              role={undefined}
              placement='bottom-start'
              transition
              disablePortal
              style={{background:'transparent',zIndex:10}}
            >
              {({ TransitionProps, placement }) => (
                <Grow
                  {...TransitionProps}
                  style={{
                    transformOrigin:
                      placement === 'bottom-start' ? 'left top' : 'left bottom',
                  }}
                >
                  <Paper style={{borderRadius:15}}>
                    <ClickAwayListener onClickAway={closeDrop}>
                      <div className='fb dropdownmenu'>
                      <div className='toptri fade-up plattop'></div>
                        <div>
                        {platcats.map((item,index)=>{
                          let urll = "/" + item.name.replace(" ", "-")

                        return(
                          <>
                            <Link key={index} to={item.bread} state={{ breadCrumb: item.bread}} className={index == 0 ? '':'subMenu'} onClick={()=>{setPlatDOpen(false);setVal(2)}}>
                              {item.name}
                              </Link><br/>
                          </>

                        )
                        })}
                        </div>
                      </div>
                    </ClickAwayListener>
                  </Paper>
                </Grow>
              )}
            </Popper>
          </div>
        </Stack>
      );
    }