import React, { createContext, useState } from 'react';

const SpotContext = createContext();

const SpotContextProvider = ({ children }) => {
    const [priceS, setPriceS] = React.useState(0);
    const [priceG, setPriceG] = React.useState(0);
    const [priceP, setPriceP] = React.useState(0);

  return (
    <SpotContext.Provider value={{ priceS,priceG,priceP, setPriceS,setPriceG,setPriceP }}>
      {children}
    </SpotContext.Provider>
  );
};

export { SpotContext, SpotContextProvider };
