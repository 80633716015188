import DeleteIcon from '@mui/icons-material/Delete';
import { Avatar, Button, CircularProgress, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, IconButton, List, ListItem, ListItemAvatar, ListItemButton, ListItemText, TextField } from '@mui/material';
import Paper from '@mui/material/Paper';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell, { tableCellClasses } from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import { styled } from '@mui/material/styles';
import * as React from 'react';
import SummaryBlock from '../SmallComponents/SummaryBlock.tsx';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import { getFunctions, httpsCallable } from "firebase/functions";
import { UserContext } from '../../providers/UserProvider.jsx';
import { getAuth, signInAnonymously } from "firebase/auth";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import NoAccountsIcon from '@mui/icons-material/NoAccounts';
import { Link, useNavigate } from 'react-router-dom';
import { useTimer } from '../../providers/TImeProvider.jsx';
import { useCheckout } from '../../providers/CheckoutProvider.jsx';
import { TimerBlock } from '../SmallComponents/TImerBlock.tsx';
import { auth } from '../../config.tsx';
import {Helmet} from "react-helmet";
import AutorenewIcon from '@mui/icons-material/Autorenew';

export function formatPrice(num){
  return parseFloat(String(num)).toFixed(2)
}
export interface SimpleDialogProps {
  open: boolean;
  selectedValue: string;
  onClose: (value: string) => void;
}
export default function CartPage() {
    const [screenWidth, setScreenWidth] = React.useState(window.innerWidth);
    const functions = getFunctions();
    const [cartItems, setCartItems] = React.useState([])
    const [isLoading, setIsLoading] = React.useState(true)
    const [sumUpdate, setSumUpdate] = React.useState(false)
    const [calculatedtotal, setCalculatedTotal] = React.useState(0)
    const user = React.useContext(UserContext);
    const [openLogin, setOpenLogin] = React.useState(false);
    const [selectedValueLogin, setSelectedValueLogin] = React.useState('');
    const nav = useNavigate()
    const { timerSeconds,setTax,setCartSubTotal,setCart,setShippingPrice,setCartFinalTotal,setDisableContinue ,gettTimerSecondsC,setTimerSecondsC} = useCheckout();

    const handleCloseLogin = (value: string) => {
      setOpenLogin(false);
      setSelectedValueLogin(value);
    };


function SimpleDialog(props: SimpleDialogProps) {
  const { onClose, selectedValue, open } = props;

  const handleClose = () => {
    onClose(selectedValue);
  };

  const handleListItemClick = (value: string) => {
    setSelectedValueLogin(value)
    setOpenLogin(false)
    if(value =="login"){
      nav("/login")
    }
    if(value == "guest"){
      createGuest()
    }
  };
  const createGuest = async () => {
    try {
      const auth = getAuth();
      const res = await signInAnonymously(auth)
        .then((res) => {
          // Signed in..
          alert("guest account created.")
        })
        .catch((error) => {
          const errorCode = error.code;
          const errorMessage = error.message;
          // ...
        });
    } catch (error) {
      console.error('Error fetching data:', error);
    }
  };
  return (
    <Dialog onClose={handleClose} open={open}>
      <DialogTitle>Choose Checkout Process Type</DialogTitle>
      <List sx={{ pt: 0 }}>
          <ListItem disableGutters key={1}>
            <ListItemButton onClick={() => handleListItemClick("login")}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor: '#FFC02C', color: 'white',margin:1 }}>
                  <AccountCircleIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Login to Account" className='dialogMessage'/>
            </ListItemButton>
          </ListItem>
          <ListItem disableGutters key={2}>
            <ListItemButton onClick={() => handleListItemClick("guest")}>
              <ListItemAvatar>
                <Avatar sx={{ bgcolor:'#FFC02C', color: 'white' ,margin:1 }}>
                  <NoAccountsIcon />
                </Avatar>
              </ListItemAvatar>
              <ListItemText primary="Guest Account" className='dialogMessage'/>
            </ListItemButton>
          </ListItem>
      </List>
    </Dialog>
  );
}
    // Create a function to update the screenWidth state
    const updateScreenWidth = () => {
      setScreenWidth(window.innerWidth);
    };

    React.useEffect(() => {
      // Add an event listener for the "resize" event
      window.addEventListener('resize', updateScreenWidth);
  
      // Clean up the event listener when the component unmounts
      return () => {
        window.removeEventListener('resize', updateScreenWidth);
      };
    }, []); // Empty dependency array to run the effect only once
    const StyledTableCell = styled(TableCell)(({ theme }) => ({
        [`&.${tableCellClasses.head}`]: {
          backgroundColor: theme.palette.common.black,
          color: theme.palette.common.white,
        },
        [`&.${tableCellClasses.body}`]: {
          fontSize: 14,
        },
      }));
      
      const StyledTableRow = styled(TableRow)(({ theme }) => ({
        '&:nth-of-type(odd)': {
          backgroundColor: theme.palette.action.hover,
        },
        // hide last border
        '&:last-child td, &:last-child th': {
          border: 0,
        },
      }));
      
      function createData(
        name: string,
        price: number,
        quantity:number
      ) {
        return { name, price,quantity };
      }
      
      const rows = [
      ];
      var imageString = "/static/images/goldbaby.jpeg";
      React.useEffect(() => {
        window.scrollTo(0, 0)
      }, [])


      const [open, setOpen] = React.useState(false);

      const handleClickOpen = () => {
        setOpen(true);
      };
    

      const [timeLeft, setTimeLeft] = React.useState(3000);

    /*React.useEffect(()=>{
      while(timeLeft>0){
        //sleep(1000)
        setTimeLeft(timeLeft -1)
      }

    },[])*/

   /* function sleep(delay) {
      var start = new Date().getTime();
      while (new Date().getTime() < start + delay) ;
  }*/
  
  function getValidatedUser() {
    return new Promise((resolve, reject) => {
      const unsubscribe = auth
        .onAuthStateChanged(
          (user) => {
            unsubscribe();
            resolve(user);
          },
          reject // pass up any errors attaching the listener
        );
    });
  }
  async function getUser(){
    const user = await getValidatedUser();
    if(user == undefined || user == null){
      setOpenLogin(true)
      return
    }else{
      setOpenLogin(false)
    }
    loadCardData()
  }


    React.useEffect(()=>{
      setShippingPrice(0)
      setCartFinalTotal(0)
      setTax(0)
      setCartSubTotal(0)
      setDisableContinue(true)
      // get cart ids
      getUser()

    },[user])

    async function loadCardData() {
      var cartV = JSON.parse(String(localStorage.getItem("cart")))
      var items:any = []
      if(cartV != undefined && cartV != null){
        for(var i = 0; i < cartV.length; i++){
          items.push({id:cartV[i].item.objectID,qty:cartV[i].qty,orderitemid:cartV[i].orderitemid})
        }
      }
      if(items.length == 0){
        setIsLoading(false)
        setCartItems([])
        return
      }else{
        //resetTimer()
        //startTimer()
      }

      const addMessage =  await httpsCallable(functions, 'cartPriceCalc');
      addMessage({items:cartV})
      .then((result) => {
        setCalculatedTotal(result.data.total)
        setCartSubTotal(result.data.total)
        setCart(result.data.currentCart)
        setCartItems(result.data.currentCart)
        setIsLoading(false)
        console.log(result)
        //resetTimer()
        //startTimer()
        /*if(cartV != undefined && cartV != null && cartV.length > 0){
          resetTimer()
          startTimer()
        }*/
        setDisableContinue(false)
        setSumUpdate(true)
      }).catch((err)=>{
        console.log(err.message)
        alert(err.message)
        localStorage.setItem('cart', JSON.stringify([]));
        setCartItems([])
        setIsLoading(false)
        setDisableContinue(true)
        setSumUpdate(true)
        if(err.message == "User is not subscribed."){
          let cartError = err.message + " Please subscribe or remove deal items from cart to continue."
          alert(cartError)
        }
      })

      
    }


    return (
        <div className='defPageMarg'>
          <Helmet>
            <meta charSet="utf-8" />
            <title>Cart Page</title>
            <link rel="canonical" href={window.location} />
            <meta name="description" content="Cart Page"/>
            <meta name="keywords" content="Silver, Gold, Tradding, Bars, Coins"/>
        </Helmet>
      <SimpleDialog
        selectedValue={selectedValueLogin}
        open={openLogin}
        onClose={handleCloseLogin}
      />
            <div className='defaultTitle'>
                <div>
                  Review Your Cart

                </div>
                <div>
                  <div>
                    <TimerBlock/>
                  </div>
                </div>
            </div>
            <div className='fb fw jc'>
                 <div className='box_75'>
                  {isLoading ? 
                  <div style={{margin:'auto',textAlign:'center'}}><br/>
                  <CircularProgress />
                  <div>
                    Calulating Cart using spot prices...
                  </div>
                  </div>
                   :<>
                    {screenWidth > 900 && (
                    <TableContainer component={Paper}>
                        <Table>
                            <TableHead>
                            <TableRow>
                                <StyledTableCell>Product</StyledTableCell>
                                <StyledTableCell align="center">Price</StyledTableCell>
                                <StyledTableCell align="center">Quantity</StyledTableCell>
                                <StyledTableCell align="center" style={{paddingRight:30}}>Subtotal</StyledTableCell>
                            </TableRow>
                            </TableHead>
                            <TableBody>
                            {cartItems != undefined && cartItems.length > 0 && cartItems.map((row,index) => (
                                <DesktopCartRow key={index} row={row}/>
                            ))}
                            {cartItems != undefined && cartItems.length == 0 && cartItems.length == 0 &&
                                        <StyledTableRow key={1}>
                                        <StyledTableCell style={{height:212,padding:20}} colSpan={4}><div>No Items</div></StyledTableCell>
                                        </StyledTableRow>
                              }
                            </TableBody>
                        </Table>
                        </TableContainer>
                    )}
                    {screenWidth < 900 && (
                        <>
                        <div style={{width:'100%'}}>
                            <hr/>
                            {cartItems != undefined && cartItems.length > 0 && cartItems.map((row,index) => (
                                <MobileCartRow row={row} key={index}/>
                            ))}
                            {cartItems != undefined && cartItems.length == 0 ? <div style={{height:50,padding:20,marginBottom:30,textAlign:'center',fontWeight:'bold'}}>No Items</div>:<></>}
                        </div>
                        </>
                    )}
                    </>
                            }
                    </div>
                <div>
                    <SummaryBlock calculatedtotal={calculatedtotal} sumUP={sumUpdate}/>
                </div>
            </div>
        </div>
    )

    function deleteItem(deleteitemm){
      let currentCart = cartItems
      if(currentCart != null && currentCart != undefined){
      let newArray = currentCart.filter((item:any) => {
        return item.orderitemid !== deleteitemm.orderitemid
      })
      setCartItems(newArray)
      localStorage.setItem("cart",JSON.stringify(newArray))
      loadCardData()
      if(newArray.length == 0 ){
        setCalculatedTotal(0)
        setDisableContinue(true)
      }
      
    }
    }

    function updateItemQty(orderItemId,qty){
      const storageData = JSON.parse(localStorage.getItem('cart')) || [];

      // Loop through the array to find the object with the specified key-value pair
      for (let i = 0; i < storageData.length; i++) {
        if (storageData[i]['orderitemid'] === orderItemId) {
          // Update the object's value with the new value
          storageData[i]['qty'] = parseInt(qty);
    
          // Save the updated array back to localStorage
          localStorage.setItem('cart', JSON.stringify(storageData));
          return; // Object updated, exit the function
        }
      }
    }

    function updateItemPrice(orderItemId,price){
      const storageData = JSON.parse(localStorage.getItem('cart')) || [];

      // Loop through the array to find the object with the specified key-value pair
      for (let i = 0; i < storageData.length; i++) {
        if (storageData[i]['orderitemid'] === orderItemId) {
          // Update the object's value with the new value
          storageData[i]['cPrice'] = price
    
          // Save the updated array back to localStorage
          localStorage.setItem('cart', JSON.stringify(storageData));
          return; // Object updated, exit the function
        }
      }
    }
    

    function MobileCartRow(props){
        var row = props.row
        const [currentQ,setCurrentQ] = React.useState(1)
        const [newCurrentQ,setNewCurrentQ] = React.useState(1)
        React.useEffect(()=>{
          setNewCurrentQ(parseInt(props.row.qty))
          setCurrentQ(parseInt(props.row.qty))
        },[])
        var price = row.calculatedPrice
        var singlePrice = row.calculatedSinglePrice
        function resetCart(){
          {
            if(newCurrentQ == currentQ){
              return
            }
            if(newCurrentQ < 0 ){
              deleteItem(row)
              alert("Item Removed.")
              return
            }
            var diff = newCurrentQ - currentQ
            if(newCurrentQ == 0 ){
              deleteItem(row)
              return
            }
            if(newCurrentQ > currentQ && diff > 0){
              alert("Added: " + diff)
            }else{
              alert("Removed: " + diff*-1)
            }
            setCurrentQ(newCurrentQ)
            updateItemQty(row.orderitemid,newCurrentQ)
            var cartV = JSON.parse(String(localStorage.getItem("cart")))

            const addMessage = httpsCallable(functions, 'cartPriceCalc');
            addMessage({items:cartV})
            .then((result) => {
              setCalculatedTotal(result.data.total)
              setCartSubTotal(result.data.total)
              setCart(result.data.currentCart)
              setCartItems(result.data.currentCart)
              setIsLoading(false)
              //resetTimer()
              //startTimer()
              setDisableContinue(false)
              setSumUpdate(true)
              
            })
          }
        
        }

        function escapeUrl(url) {
          // Escape '/' and space characters in the URL
          const escapedUrl = encodeURIComponent(url).replace(/%20/g, '+').replace(/%2F/g, '/').replaceAll("/","")
          return escapedUrl;
        }
      
        let prodUrl = '/product/'+escapeUrl(row?.item.name)

        return (
            <div>
                <div className='fb jc'>
                <Link to={prodUrl}>
                    <img src={row?.item.mainImage} height="150" width="150" alt={row?.item.name}/>
                </Link>
                </div>
                <div className='textC'>
                <Link to={prodUrl}>
                    {row.item.name}
                </Link>
                </div>
                <div>
                    <div className='fb jc'>
                      <div style={{margin:'auto 10px'}}>
                      ${parseFloat(singlePrice).toFixed(2)} x
                      </div>
                        <TextField style={{width:80,height:40,marginRight:25,marginBottom:30,marginTop:20}} type='number'
                        value={newCurrentQ}
                        inputProps={{ min: 0 }}
                        onInput={(e)=>{
                          setNewCurrentQ(e.target.value)
                        }}
                        />
                        <AutorenewIcon style={{marginTop:33,marginLeft:-20,marginRight:20,cursor:'pointer'}} onClick={()=>{resetCart()}}/>
                        <div style={{margin:'auto 0'}}>
                        ${parseFloat(String(price)).toFixed(2)}
                        <IconButton onClick={()=>{deleteItem(row)}} style={{cursor:'pointer'}}>
                        <DeleteIcon style={{marginLeft:10,cursor:'pointer'}}/>
                        </IconButton>
                        </div>
                    </div>
                </div>
                <hr/>
            </div>
        )
    }
    function DesktopCartRow(props){
      var row = props.row
      const [currentQ,setCurrentQ] = React.useState(1)
      const [newCurrentQ,setNewCurrentQ] = React.useState(1)
      React.useEffect(()=>{
        setNewCurrentQ(parseInt(props.row.qty))
        setCurrentQ(parseInt(props.row.qty))
      },[])
      function resetCart(){
        {
          console.log("NEW CURQ: ", newCurrentQ)
          if(newCurrentQ < 0 ){
            alert("Please reduce number to 1 or remove item.")
            return
          }
          if(newCurrentQ > parseInt(row.item.stock)){
            //setNewCurrentQ(currentQ)
            alert("Current Stock Available: "+ parseInt(row.item.stock))
            return
          }
          if(newCurrentQ == currentQ){
            return
          }
          var diff = newCurrentQ - currentQ
          if(newCurrentQ == 0 ){
            deleteItem(row)
            return
          }
          if(newCurrentQ > currentQ && diff > 0){
            alert("Added: " + diff)
          }else{
            alert("Removed: " + diff*-1)
          }
          setCurrentQ(newCurrentQ)
          updateItemQty(row.orderitemid,newCurrentQ)
          var cartV = JSON.parse(String(localStorage.getItem("cart")))

          const addMessage = httpsCallable(functions, 'cartPriceCalc');
          addMessage({items:cartV})
          .then((result) => {
            setCalculatedTotal(result.data.total)
            setCartSubTotal(result.data.total)
            setCart(result.data.currentCart)
            setCartItems(result.data.currentCart)
            setIsLoading(false)
            //resetTimer()
            //startTimer()
            setDisableContinue(false)
            setSumUpdate(true)
          })
        }
      }
      var price = row.calculatedPrice
      var singlePrice = row.calculatedSinglePrice

      function escapeUrl(url) {
        // Escape '/' and space characters in the URL
        const escapedUrl = encodeURIComponent(url).replace(/%20/g, '+').replace(/%2F/g, '/').replaceAll("/","")
        return escapedUrl;
      }
    
      let prodUrl = '/product/'+escapeUrl(row?.item.name)

        return (
            <StyledTableRow key={row.index}>
            <StyledTableCell>
                <div className='fb'>
                <Link to={prodUrl}>
                  <img src={row.item.mainImage} height="100" width="100"
                  alt={row?.item.name}
                  />
                </Link>
                <div className='ctitle'>
                <Link to={prodUrl}>
                {row.item.name}
                </Link>
                </div>
                </div>
            </StyledTableCell>
            <StyledTableCell align="center"><div>${formatPrice(singlePrice)}</div></StyledTableCell>
            <StyledTableCell align="center"><div><TextField style={{width:80,height:40,marginRight:25,marginBottom:30,marginTop:20}} type='number' inputProps={{ min: 1 }}
                        value={newCurrentQ}
                        onInput={(e)=>{
                          setNewCurrentQ(e.target.value)
                        }}
                        /><AutorenewIcon className='desktopIconCartRow' style={{cursor:'pointer'}} onClick={()=>{resetCart()}}/></div></StyledTableCell>
            <StyledTableCell align="center"><div>${parseFloat(String(price)).toFixed(2)}</div>
            <IconButton onClick={()=>{deleteItem(row)}}>
              <DeleteIcon style={{marginLeft:10,cursor:'pointer'}}/>
            </IconButton>
            </StyledTableCell>
            </StyledTableRow>
        )
    }
}

