import React, { createContext, useState, useContext } from 'react';

// Create a context for the checkout process
const CheckoutContext = createContext();

// Custom hook to use the checkout context
export const useCheckout = () => {
  return useContext(CheckoutContext);
};

// CheckoutProvider component to provide the context to its children
export const CheckoutProvider = ({ children }) => {
  // State for cart data

  const [cartData, setCartData] = useState([]);
  React.useEffect(()=>{
    const storageData = JSON.parse(localStorage.getItem('cart')) || [];
    if(storageData != []){
      setCart(storageData)
    }
  },[])

  // State for address data
  const [addressData, setAddressData] = useState({});

  // State for shipping weight data
  const [shippingWeightData, setShippingWeightData] = useState(0);
  const [shippingPriceData, setShippingPriceData] = useState(0);
  const [cartSubTotal, setCartSubTotalData] = useState(0);
  const [cartFinalTotal, setCFinalTotalData] = useState(0);
  const [continueDisableButton, setContinueDisableButton] = useState(true);
  const [timerSeconds, setTimerSeconds] = useState(500)
  const [payType, setPayType] = useState("")


  const setTimerSecondsC = (cartSubTotal) => {
    setTimerSeconds(cartSubTotal);
  };
  const gettTimerSecondsC = () => {
    return timerSeconds;
  };
  const setPayTypeObj = (type) => {
    setPayType(type);
  };
  const getPayTypeObj = () => {
    return payType;
  };


  // State for tax data
  const [taxData, setTaxData] = useState(0);

  // Getters and setters for cart data
  const setCartSubTotal= (cartSubTotal) => {
    setCartSubTotalData(cartSubTotal);
  };

  const getCartSubTotal = () => {
    return cartSubTotal;
  };
  const setCartFinalTotal = (cart) => {
    let cardFee = 0
    let total = parseFloat(cartSubTotal) + shippingPriceData + taxData
    setCFinalTotalData(total)
  };

  const getCartFinalTotal = () => {

    let total = parseFloat(cartSubTotal) + shippingPriceData + taxData
    if((payType == "Credit Card" || payType == "PayPal") && window.location.href.includes("review")){
      let fee = (total * 0.039)

      total = total + fee
    }
    return total;
  };
  const setCart = (cart) => {
    setCartData(cart);
  };

  const getCart = () => {
    return cartData;
  };

  // Getters and setters for address data
  const setAddress = (address) => {
    setAddressData(address);
  };

  const getAddress = () => {
    return addressData;
  };

  // Getters and setters for shipping weight data
  const setShippingWeight = (weight) => {
    setShippingWeightData(weight);
  };

  const getShippingWeight = () => {
    return shippingWeightData;
  };

    // Getters and setters for shipping weight price
    const setShippingPrice = (shippingPriceData) => {
      setShippingPriceData(shippingPriceData);
    };
  
    const getShippingPrice = () => {
      return shippingPriceData;
    };

  // Getters and setters for tax data
  const setTax = (tax) => {
    setTaxData(tax);
  };

  const getTax = () => {
    return taxData;
  };

    // Getters and setters for tax data
    const setDisableContinue = (disable) => {
      setContinueDisableButton(disable);
    };
  
    const getDisableContinue = () => {
      return continueDisableButton;
    };

  // Value object to be provided by the context
  const value = {
    setCartSubTotal,
    getCartSubTotal,
    getCartFinalTotal,
    setCartFinalTotal,
    setCart,
    getCart,
    setAddress,
    getAddress,
    setShippingWeight,
    getShippingWeight,
    setShippingPrice,
    getShippingPrice,
    setTax,
    getTax,
    setDisableContinue,
    getDisableContinue,
    setTimerSecondsC,
    gettTimerSecondsC,
    setPayTypeObj,
    getPayTypeObj,
  };

  return (
    <CheckoutContext.Provider value={value}>
      {children}
    </CheckoutContext.Provider>
  );
};
